import { combineReducers } from 'redux';
import userLoginReducer from './userLoginReducer';
import sectionReducer from './sectionReducer';

const reducers = combineReducers({
  userLogin: userLoginReducer,
  sections: sectionReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
