import React, { useEffect } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTypedSelector } from './../../hooks/useTypedSelector';
import Header from './../../components/admin/Header';

const MainPage: React.FC = () => {
  const history = useHistory();

  const { userInfo } = useTypedSelector((state) => state.userLogin);

  useEffect(() => {
    if (!userInfo || _.isEmpty(userInfo)) {
      history.replace('/login');
    }
  }, [userInfo, history]);

  return (
    <>
      <Header />
      <div>This is the main page. This is the main page.</div>
    </>
  );
};

export default MainPage;
