import React, { useState, useEffect } from 'react';
import { Grid, TextField, Box, Button, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useCarouselContext, CarouselDataItem } from './../../../contexts/CarouselContext';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSectionHomeActions } from './../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { darken } from '@material-ui/core/styles/colorManipulator';

interface CarouselItemProps {
  tabValue: number;
  index: number;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    textField: {
      backgroundColor: theme.palette.common.white,
    },
    fileInput: {
      display: 'none',
    },
    selectImageBtnContainer: {
      display: 'flex',
      width: '100%',
    },
    imageUrlRoot: {
      backgroundColor: theme.palette.common.white,

      '& div.Mui-disabled': {
        color: theme.palette.common.black,
      },

      '& label.MuiFormLabel-root': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    successAlert: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    deleteBtn: {
      backgroundColor: theme.palette.common.red,
      color: theme.palette.getContrastText(theme.palette.common.red),

      '&:hover': {
        backgroundColor: darken(theme.palette.common.red, 0.25),
      },
    },
  };
});

// TODO: check later, need to be reusable
const Alert = (props: AlertProps) => {
  const classes = useStyles();
  return <MuiAlert elevation={4} variant='filled' {...props} classes={{ filledSuccess: classes.successAlert }} />;
};

const CarouselItem: React.FC<CarouselItemProps> = ({ tabValue, index }) => {
  const classes = useStyles();
  const [title, setTitle] = useState<string>(' ');
  const [description, setDescription] = useState<string>(' ');
  const [buttonText, setButtonText] = useState<string>(' ');
  const [buttonLink, setButtonLink] = useState<string>(' ');
  const [imageUrl, setImageUrl] = useState<string>(' ');
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { uploadImageSectionHome, uploadImageReset } = useSectionHomeActions();
  const { carouselData, setCarouselData } = useCarouselContext();

  const { loading, url, error } = useTypedSelector((state) => state.sections.home.uploadImage);

  useEffect(() => {
    if (carouselData && carouselData.length > 0) {
      setTitle(carouselData[index].title);
      setDescription(carouselData[index].description);
      setButtonText(carouselData[index].buttonText);
      setButtonLink(carouselData[index].buttonLink);
      setImageUrl(carouselData[index].imageUrl);
    }
  }, [carouselData, index]);

  useEffect(() => {
    if (!loading && url && carouselData) {
      setShowSuccess(true);
      setImageUrl(url);
      setCarouselData(
        carouselData.map((data: CarouselDataItem, idx: number) => {
          if (idx === tabValue) {
            return {
              ...data,
              imageUrl: url,
            };
          }
          return data;
        })
      );
      uploadImageReset();
    }
  }, [url, loading, uploadImageReset, setCarouselData, carouselData, tabValue]);

  useEffect(() => {
    if (error && !loading) {
      setShowError(true);
    }
  }, [loading, error]);

  const uploadImage = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    // just make sure 1 file is uploaded.
    if (!files || files.length !== 1 || !files[0].type.includes('image')) {
      return;
    }

    // console.log(files);
    uploadImageSectionHome(files[0]);
  };

  const alertCloseHandler = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  const deleteHandler = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const _carouselData = carouselData?.filter((data: CarouselDataItem, idx: number) => {
      return idx !== index ? true : false;
    });

    if (_carouselData) {
      setCarouselData(_carouselData);
    }
  };

  return (
    carouselData && (
      <>
        <Snackbar
          open={showError || showSuccess}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={5000}
          onClose={() => alertCloseHandler()}
        >
          <>
            {error ? (
              <Alert severity='error' onClose={() => alertCloseHandler()}>
                {error}
              </Alert>
            ) : (
              <Alert severity='success' onClose={() => alertCloseHandler()}>
                Image uploaded successfully.
              </Alert>
            )}
          </>
        </Snackbar>
        <Box p={3} hidden={index !== tabValue}>
          <Grid container item direction='row' spacing={3}>
            <Grid item container xs={12} direction='row' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                className={classes.deleteBtn}
                onClick={deleteHandler}
                disabled={carouselData.length === 1}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={12}>
              <TextField
                // autoFocus : TODO.. use this
                error={false}
                id='title'
                label='Title'
                helperText=''
                variant='outlined'
                fullWidth
                value={title}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setTitle(evt.target.value);
                  if (setCarouselData) {
                    setCarouselData(
                      carouselData.map((data: CarouselDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            title: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={false}
                id='description'
                label='Description'
                helperText=''
                variant='outlined'
                fullWidth
                value={description}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setDescription(evt.target.value);
                  if (setCarouselData) {
                    setCarouselData(
                      carouselData.map((data: CarouselDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            description: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                error={false}
                id='buttonText'
                label='Button Text'
                helperText=''
                variant='outlined'
                fullWidth
                value={buttonText}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setButtonText(evt.target.value);
                  if (setCarouselData) {
                    setCarouselData(
                      carouselData.map((data: CarouselDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            buttonText: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                error={false}
                id='buttonLink'
                label='Button Link'
                helperText=''
                variant='outlined'
                fullWidth
                value={buttonLink}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setButtonLink(evt.target.value);
                  if (setCarouselData) {
                    setCarouselData(
                      carouselData.map((data: CarouselDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            buttonLink: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={9}>
              <TextField
                error={false}
                contentEditable={false}
                id='image-url'
                label='Image Url'
                helperText=''
                variant='outlined'
                fullWidth
                value={imageUrl}
                disabled={true}
                classes={{
                  root: classes.imageUrlRoot,
                }}
              />
            </Grid>
            <Grid item container xs={3}>
              <input
                accept='image/*'
                className={classes.fileInput}
                id='contained-button-file'
                type='file'
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  uploadImage(evt);
                }}
              />
              <label htmlFor='contained-button-file' className={classes.selectImageBtnContainer}>
                <Button variant='contained' color='default' component='span' fullWidth>
                  Upload Image
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  );
};

export default CarouselItem;
