import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import { useTypedSelector } from './../hooks/useTypedSelector';
import { Service } from './../state/typings';
import striptags from 'striptags';
import { darken } from '@material-ui/core/styles/colorManipulator'; /* https://stackoverflow.com/questions/47268652/jss-how-to-change-opacity-for-a-color */

// react-icons
import { IoRestaurantOutline, IoHammerOutline } from 'react-icons/io5';
import { FaBath, FaUmbrellaBeach } from 'react-icons/fa';
import { BiBath, BiCabinet } from 'react-icons/bi';
import { GiBathtub, GiPaintBucket, GiBrickWall, GiParkBench } from 'react-icons/gi';
import { GoPaintcan } from 'react-icons/go';

const removeExtraParagraph = (htmlString: string) => {
  return htmlString.replaceAll('<p><br></p>', '');
};

const trimParagraph = (htmlString: string, wordCount: number) => {
  const stripped = striptags(htmlString, '', ' ');
  const strippedArray = stripped.split(' ');
  const cleansedStrippedArray = strippedArray.filter(Boolean); // removes empty array elements
  return cleansedStrippedArray.slice(0, wordCount).join(' ') + '...';
};

const iconComponents = [
  {
    code: 'IoRestaurantOutline',
    type: IoRestaurantOutline,
  },
  {
    code: 'IoHammerOutline',
    type: IoHammerOutline,
  },
  {
    code: 'FaBath',
    type: FaBath,
  },
  {
    code: 'FaUmbrellaBeach',
    type: FaUmbrellaBeach,
  },
  {
    code: 'BiBath',
    type: BiBath,
  },
  {
    code: 'BiCabinet',
    type: BiCabinet,
  },
  {
    code: 'GiBathtub',
    type: GiBathtub,
  },
  {
    code: 'GiPaintBucket',
    type: GiPaintBucket,
  },
  {
    code: 'GiBrickWall',
    type: GiBrickWall,
  },
  {
    code: 'GiParkBench',
    type: GiParkBench,
  },
  {
    code: 'GoPaintcan',
    type: GoPaintcan,
  },
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionHeader: {
      fontSize: '2.25rem',
      fontWeight: 800,
    },
    servicesWrapper: {
      justifyContent: 'center',

      paddingTop: '2em',
      paddingBottom: '2em',
      paddingLeft: '12em',
      paddingRight: '12em',

      [theme.breakpoints.down('md')]: {
        paddingLeft: '3em',
        paddingRight: '3em',
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: '1.5em',
        paddingBottom: '1.5em',
      },

      '& div': {
        // borderStyle: 'solid',
        // borderWidth: '0.001rem',
        justifyContent: 'center',
      },
    },
    serviceTitle: {
      fontSize: '1.25rem',
      fontWeight: 600,
      textAlign: 'center',
      paddingBottom: '0.50em',
    },
    serviceTeaser: {
      textAlign: 'center',
      marginBottom: '0.5em',
    },
    serviceSubTitle: {
      color: theme.palette.common.black,
      fontSize: '1.15rem',
      fontWeight: 'bold',
    },
    serviceFullDescription: {
      color: theme.palette.common.black,
    },
    serviceIcon: {
      color: theme.palette.common.blueIcon,
      marginBottom: '1em',
    },
    serviceWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start !important',
      alignItems: 'center',
      padding: '1.5em',
    },
    mainDescriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    buttonContainedPrimary: {
      textTransform: 'none',
      fontWeight: 500,
      fontSize: '0.90rem',
      color: theme.palette.common.white,
    },
    buttonContainedSecondary: {
      // textTransform: 'none',
      fontWeight: 900,
      // fontSize: '1.20rem',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.greenButton,
      '&:hover': {
        backgroundColor: darken(theme.palette.common.greenButtonHover, 0.1),
      },
    },
    dialogTitleRoot: {
      '& h2': {
        fontWeight: 900,
      },
    },
    dialogActionRoot: {
      justifyContent: 'space-between',
      paddingRight: '24px',
      paddingLeft: '24px',
    },
    imageLeft: {
      [theme.breakpoints.up('sm')]: {
        paddingRight: '0.75em',
      },
    },
    textRight: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '0.75em',
      },
    },
    contactUsButton: {
      color: theme.palette.common.greenButton,
    },
  };
});

const getIconComponent = (code: string, className: any) => {
  const foundIcon = iconComponents.find((icon) => icon.code === code);
  if (foundIcon) {
    return React.createElement(foundIcon.type, { size: 56, className });
  }
  return null;
};

const ServicesSection: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');
  const [selectedServiceIndex, setSelectedServiceIndex] = useState<number>(0);
  const { loading, error, content } = useTypedSelector((state) => state.sections.all);

  const descriptionElementRef = React.useRef<HTMLElement>(null);

  const handleClickOpen = (scrollType: DialogProps['scroll'], selectedServiceIndex: number) => () => {
    setSelectedServiceIndex(selectedServiceIndex);
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  if (!loading && content && content.services) {
    const { description, services } = content.services.content;

    return (
      <Container disableGutters maxWidth='xl'>
        <Grid container direction='row' className={classes.servicesWrapper}>
          {/* main description */}
          <Grid item container xs={12} className={classes.mainDescriptionWrapper}>
            <Typography className={classes.sectionHeader}>Our Services</Typography>
            <Typography className={classes.serviceTeaser}>
              <div dangerouslySetInnerHTML={{ __html: removeExtraParagraph(description) }} />
            </Typography>
          </Grid>
          {/* list of services */}
          {services &&
            services.map((service: Service, index: number) => (
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12} container className={classes.serviceWrapper}>
                {getIconComponent(service.icon, classes.serviceIcon)}
                <Typography className={classes.serviceTitle}>{service.title}</Typography>
                <Typography className={classes.serviceTeaser}>
                  <div
                    dangerouslySetInnerHTML={{ __html: trimParagraph(removeExtraParagraph(service.description), 50) }}
                  />
                </Typography>
                <Button
                  variant='contained'
                  size='small'
                  color='primary'
                  classes={{
                    containedPrimary: classes.buttonContainedPrimary,
                  }}
                  onClick={handleClickOpen('paper', index)}
                >
                  More ...
                </Button>
              </Grid>
            ))}
          {/* dialog full services */}
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            aria-labelledby='scroll-dialog-title'
            aria-describedby='scroll-dialog-description'
            fullWidth={true}
            maxWidth='lg'
          >
            <DialogTitle
              id='scroll-dialog-title'
              classes={{
                root: classes.dialogTitleRoot,
              }}
            >
              {services[selectedServiceIndex].title}
            </DialogTitle>
            <DialogContent dividers={scroll === 'paper'}>
              <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
                <Container disableGutters>
                  <Grid container direction='row'>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.imageLeft}>
                      <img
                        src={services[selectedServiceIndex].imageUrl}
                        alt={services[selectedServiceIndex].imageUrl}
                        width='100%'
                      />
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className={classes.textRight}>
                      <div
                        className={classes.serviceSubTitle}
                        dangerouslySetInnerHTML={{
                          __html: services[selectedServiceIndex].subTitle,
                        }}
                      />
                      <div
                        className={classes.serviceFullDescription}
                        dangerouslySetInnerHTML={{
                          __html: removeExtraParagraph(`${services[selectedServiceIndex].description}`),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Container>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              classes={{
                root: classes.dialogActionRoot,
              }}
            >
              <Button
                color='secondary'
                variant='contained'
                classes={{
                  containedSecondary: classes.buttonContainedSecondary,
                }}
                disabled={true}
              >
                Contact Us
              </Button>
              <Button onClick={handleClose} color='primary'>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    );
  } else {
    return null;
  }
};

export default ServicesSection;
