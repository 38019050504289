import React, { useState, useEffect, useReducer } from 'react';
import { Card, CardContent, CardHeader, Tabs, Tab, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CarouselItem from './CarouselItem';
import { useCarouselContext, CarouselDataItems } from './../../../contexts/CarouselContext';
import { useSectionHomeActions } from './../../../hooks/useActions';
import _ from 'lodash';

interface TabPanelsProps {
  tabValue: number;
}

interface CarouselTabsProps {
  initialCarouselData: CarouselDataItems;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    carouselTitle: {
      fontSize: '1rem',
    },
    tabButtonNormal: {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      minWidth: '5em !important',
    },
    tabButtonSelected: {
      backgroundColor: theme.palette.grey[100],
    },
    tabpanelContainer: {
      backgroundColor: theme.palette.grey[100],
    },
    tabIndicator: {
      backgroundColor: theme.palette.grey[100],
    },
    tabButtonNew: {
      marginLeft: '12px',
      marginTop: '7px',
      height: '2rem',
    },
  };
});

const CarouselTabs: React.FC<CarouselTabsProps> = ({ initialCarouselData = [] }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<number>(0);
  const CAROUSEL_LIMIT = 5;

  const { uploadImageReset } = useSectionHomeActions();

  const TabButtons = ({ tabValue }: TabPanelsProps): JSX.Element => {
    // below code was taken from https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    // TODO: study about 'useReducer' more in detail, for now just use this snippet to force rerender
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const { carouselData, setCarouselData } = useCarouselContext();

    // to set the tabValue when user 'deletes' a tab
    useEffect(() => {
      if (!_.isEmpty(carouselData) && tabValue === carouselData?.length) {
        setTabValue(tabValue - 1);
      }
    }, [carouselData, tabValue]);

    const isNewButtonDisabled = () => {
      return carouselData && !_.isEmpty(carouselData) && carouselData.length >= CAROUSEL_LIMIT ? true : false;
    };

    const NewButton = (): JSX.Element => {
      return (
        <Button
          variant='contained'
          disabled={isNewButtonDisabled()}
          color='primary'
          size='small'
          onClick={() => {
            const newCarouselData = carouselData && carouselData.length > 0 ? carouselData : [];
            newCarouselData.push({
              title: '',
              description: '',
              buttonText: '',
              buttonLink: '',
              imageUrl: 'https://',
            });
            setCarouselData(newCarouselData);
            forceUpdate();
            if (carouselData?.length) {
              setTabValue(carouselData.length - 1);
            }
          }}
          className={classes.tabButtonNew}
        >
          New
        </Button>
      );
    };

    return (
      <Tabs
        value={tabValue}
        // indicatorColor='secondary'
        // textColor='secondary'
        classes={{
          indicator: classes.tabIndicator,
        }}
      >
        {carouselData?.map((carouselDataItem, index) => (
          <Tab
            key={index + 1}
            label={index + 1}
            onClick={() => setTabValue(index)}
            classes={{
              root: classes.tabButtonNormal,
              selected: classes.tabButtonSelected,
            }}
          />
        ))}
        <Tab component={NewButton} />
      </Tabs>
    );
  };

  const TabPanels = ({ tabValue }: TabPanelsProps): JSX.Element => {
    const { setCarouselData, carouselData } = useCarouselContext();

    useEffect(() => {
      if (!carouselData) {
        setCarouselData(initialCarouselData);
      }
    }, [carouselData, setCarouselData]);

    return (
      <div className={classes.tabpanelContainer}>
        {carouselData?.map((carouselDataItem, index) => {
          return <CarouselItem key={index} index={index} tabValue={tabValue} />;
        })}
      </div>
    );
  };

  return (
    <Card variant='outlined'>
      <CardHeader
        title='Carousel'
        classes={{
          title: classes.carouselTitle,
        }}
      />
      <CardContent>
        <TabButtons tabValue={tabValue} />
        <TabPanels tabValue={tabValue} />
      </CardContent>
    </Card>
  );
};

export default CarouselTabs;
