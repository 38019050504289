import React from 'react';
import { useTypedSelector } from './../../hooks/useTypedSelector';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { getThumbnailUrl } from '../../utility';
import { Gallery, Item } from 'react-photoswipe-gallery';
import Loader from './../../components/Loader';
import theme from '../../Theme';

interface GalleryDetailsProps {
  code: string;
  setSelectedGallery: React.Dispatch<React.SetStateAction<string | null>>;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    galleryDetailsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',

      '& img': {
        margin: '7px',
        borderStyle: 'solid',
        borderWidth: '0.05em',
        borderColor: 'grey',
        boxShadow: '4px 4px 4px grey',

        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    subHeader: {
      fontSize: '1.25rem',
      fontWeight: 800,
      paddingBottom: '0.50rem',
      textAlign: 'center',

      '& span:hover': {
        cursor: 'pointer',
      },
    },
  };
});

const GalleryDetails: React.FC<GalleryDetailsProps> = ({ code, setSelectedGallery }) => {
  const classes = useStyles();
  const { loading, error, content } = useTypedSelector((state) => state.sections.all);

  if (loading) {
    return <Loader loading={loading} />;
  }

  if (!loading && content && content.gallery) {
    const { gallery } = content.gallery.content;
    const indexToSlice = parseInt(code) - 1;
    const filteredGallery = gallery.slice(indexToSlice, indexToSlice + 1);

    if (filteredGallery && filteredGallery.length > 0 && filteredGallery[0].images) {
      return (
        <>
          <Typography className={classes.subHeader}>
            <span
              onClick={() => {
                setSelectedGallery(null);
              }}
            >
              Return to <span style={{ color: theme.palette.common.blueButtonHover }}>Main Gallery</span>
            </span>
          </Typography>
          <Gallery>
            <div className={classes.galleryDetailsWrapper}>
              {filteredGallery[0].images.map(({ url, width, height }) => {
                return (
                  <Item original={url} width={width} height={height}>
                    {({ ref, open }: { ref: React.MutableRefObject<any>; open: () => void }) => (
                      <img ref={ref} onClick={open} src={getThumbnailUrl(url)} />
                    )}
                  </Item>
                );
              })}
            </div>
          </Gallery>
        </>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default GalleryDetails;
