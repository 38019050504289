import React, { useContext, createContext, useState } from 'react';

export interface CarouselDataItem {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  imageUrl: string;
}

export interface CarouselDataItems extends Array<CarouselDataItem> {}

export interface CarouselContextValue {
  carouselData: CarouselDataItems | null;
  setCarouselData: React.Dispatch<React.SetStateAction<CarouselDataItems | null>>;
}

export const carouselDataDefaultValue: CarouselContextValue = {
  carouselData: [],
  setCarouselData: () => {},
};

export const CarouselContext = createContext<CarouselContextValue>(carouselDataDefaultValue);
export const useCarouselContext = () => useContext(CarouselContext);

export const CarouselContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [carouselData, setCarouselData] = useState<CarouselDataItems | null>(null);

  // useEffect(() => {
  //   debugger;
  //   console.log(carouselData);
  // }, [carouselData]);

  return (
    <CarouselContext.Provider
      value={{
        carouselData,
        setCarouselData,
      }}
    >
      {children}
    </CarouselContext.Provider>
  );
};

/*
resources:
https://www.youtube.com/watch?v=OseG8oQ2RDM
https://wanago.io/2020/09/28/react-context-api-hooks-typescript/
*/
