import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { darken } from '@material-ui/core/styles/colorManipulator'; /* https://stackoverflow.com/questions/47268652/jss-how-to-change-opacity-for-a-color */
import Header from '../../../components/admin/Header';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSectionHomeActions } from './../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import CarouselTabs from './CarouselTabs';
import { useCarouselContext, CarouselContextProvider } from './../../../contexts/CarouselContext';
import { ContentHome, Carousel } from './../../../state/typings';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingTop: '2rem',
    },
    formContainer: {
      paddingTop: '1em',
    },
    buttonContainedPrimary: {
      backgroundColor: theme.palette.common.adminGreenButton,
      '&:hover': {
        backgroundColor: darken(theme.palette.common.adminGreenButton, 0.1),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
    successAlert: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    saveCancelButtons: {
      '& button': {
        margin: '5px',
      },
    },
  };
});

// TODO: check later, need to be reusable
const Alert = (props: AlertProps) => {
  const classes = useStyles();
  return <MuiAlert elevation={4} variant='filled' {...props} classes={{ filledSuccess: classes.successAlert }} />;
};

const HomeSection: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>();
  const [title, setTitle] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [workHours, setWorkHours] = useState<string>('');
  const [workDays, setWorkDays] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { fetchSectionHome, updateSectionHome } = useSectionHomeActions();

  const { userInfo } = useTypedSelector((state) => state.userLogin);
  const {
    loading: loadingFetch,
    content: contentFetch,
    error: errorFetch,
  } = useTypedSelector((state) => state.sections.home.fetch);
  const {
    loading: loadingUpdate,
    content: contentUpdate,
    error: errorUpdate,
  } = useTypedSelector((state) => state.sections.home.update);

  useEffect(() => {
    if (!userInfo || _.isEmpty(userInfo)) {
      history.replace('/login');
    }
  }, [userInfo, history]);

  useEffect(() => {
    fetchSectionHome();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingFetch && contentFetch) {
      // alert('content was retrieved');

      setTitle(contentFetch.title);
      setEmail(contentFetch.email);
      setPhone(contentFetch.phone);
      setWorkHours(contentFetch.workHours);
      setWorkDays(contentFetch.workDays);
    }
  }, [loadingFetch, contentFetch]);

  useEffect(() => {
    if (!loadingUpdate && contentUpdate) {
      fetchSectionHome();
      setShowSuccess(true);
    }
  }, [loadingUpdate, contentUpdate, fetchSectionHome]);

  useEffect(() => {
    if (!loadingUpdate && errorUpdate) {
      setShowError(true);
    }
  }, [loadingUpdate, errorUpdate]);

  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  const saveForm = (carouselData: any) => {
    //assemble json for posting
    // TODO, use lodash to remove, '_id' in carouselData

    const contentHome: ContentHome = {
      title,
      email,
      phone,
      workHours,
      workDays,
      carousel: carouselData.map((data: Carousel) => _.omit(data, ['_id'])),
    };

    updateSectionHome(contentHome);
  };

  const SaveCancelButtons = (): JSX.Element => {
    const { carouselData } = useCarouselContext();

    return (
      <div className={classes.saveCancelButtons}>
        <Button
          size='large'
          variant='contained'
          color='primary'
          classes={{ containedPrimary: classes.buttonContainedPrimary }}
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => saveForm(carouselData)}
        >
          Save
        </Button>
        <Button
          size='large'
          variant='contained'
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => history.push('/admin')}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const alertCloseHandler = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  return (
    <>
      <Snackbar
        open={showError || showSuccess}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        onClose={() => alertCloseHandler()}
      >
        <>
          {errorUpdate ? (
            <Alert severity='error' onClose={() => alertCloseHandler()}>
              {errorUpdate}
            </Alert>
          ) : (
            <Alert severity='success' onClose={() => alertCloseHandler()}>
              Home Section successfully saved.
            </Alert>
          )}
        </>
      </Snackbar>
      <Header />
      <Container maxWidth='md' className={classes.container}>
        <CarouselContextProvider>
          <form noValidate autoComplete='off'>
            <Grid container direction='column'>
              <Grid container direction='row' justifyContent='center' item>
                <Typography variant='h4'>Home Section</Typography>
              </Grid>
              {!loadingFetch && contentFetch && (
                <Grid container direction='row' item spacing={3} className={classes.formContainer}>
                  <Grid item xs={12}>
                    <TextField
                      error={false}
                      id='title'
                      label='Title'
                      helperText=''
                      variant='outlined'
                      fullWidth
                      value={title}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setTitle(evt.target.value);
                      }}
                      autoFocus={true}
                      spellCheck={false}
                      inputRef={setTextInputRef}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={false}
                      id='email'
                      label='Email'
                      helperText=''
                      variant='outlined'
                      fullWidth
                      value={email}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setEmail(evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={false}
                      id='phone'
                      label='Phone'
                      helperText=''
                      variant='outlined'
                      fullWidth
                      value={phone}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setPhone(evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={false}
                      id='workHours'
                      label='Work Hours'
                      helperText=''
                      variant='outlined'
                      fullWidth
                      value={workHours}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setWorkHours(evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={false}
                      id='workDays'
                      label='Work Days'
                      helperText=''
                      variant='outlined'
                      fullWidth
                      value={workDays}
                      onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                        setWorkDays(evt.target.value);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <CarouselTabs initialCarouselData={contentFetch.carousel} />
                  </Grid>
                  <Grid item container direction='row' justifyContent='center' xs={12}>
                    <SaveCancelButtons />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </CarouselContextProvider>
      </Container>
      <Backdrop className={classes.backdrop} open={loadingUpdate}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default HomeSection;
