import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface TopSectionSensorProps {
  setShowToTopButton: (value: boolean) => void;
}

const TopSectionSensor: React.FC<TopSectionSensorProps> = ({ setShowToTopButton }: TopSectionSensorProps) => {
  const onChangeHandler = (isVisible: boolean) => {
    setShowToTopButton(!isVisible);
  };

  return (
    <VisibilitySensor onChange={onChangeHandler}>
      <div
        style={{
          height: '2px',
          color: 'white',
        }}
      >
        <span
          style={{
            display: 'none',
          }}
        >
          This is just a sensor, this text should not be displayed!
        </span>
      </div>
    </VisibilitySensor>
  );
};

export default TopSectionSensor;
