import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from './reducers';
import { userLoginReducerDefaultState } from './reducers/userLoginReducer';

const middleware = [thunk];

const userInfo = localStorage.getItem('userInfo');
const userInfoFromStorage = userInfo ? JSON.parse(userInfo) : {};

const initialState = {
  userLogin: {
    ...userLoginReducerDefaultState,
    userInfo: userInfoFromStorage,
  },
};
export const store = createStore(
  reducers,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
