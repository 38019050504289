import { useSelector, TypedUseSelectorHook } from 'react-redux';
import { RootState } from './../state';

/*
basically, what the below code is saying is that, instead of using the standard hook 'useSelector', 
use a new hook called 'useTypedSelector' that has a type of 'TypedUseSelectorHook' (from react-redux) 
that accepts the RootState.
 */

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
