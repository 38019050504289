import axios from 'axios';
import { Dispatch } from 'redux';
import { ActionType } from './../action-types';
import { UserAction } from './../actions';
import { RootState } from './../index'; // state
import { UserInfo } from './../typings';

export const loginUser = (email: string, password: string) => {
  return async (dispatch: Dispatch<UserAction>, getState: () => RootState) => {
    try {
      dispatch({ type: ActionType.USER_LOGIN_REQUEST });

      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      };

      const { data }: { data: UserInfo } = await axios.post(
        `${process.env.REACT_APP_NODEJS_API_URL}/api/users/login`,
        { email, password },
        config
      );

      dispatch({ type: ActionType.USER_LOGIN_SUCCESS, payload: data });

      localStorage.setItem('userInfo', JSON.stringify(data));
    } catch (error: any) {
      let errorMessage = 'unknown error';
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      dispatch({
        type: ActionType.USER_LOGIN_FAIL,
        payload: errorMessage,
      });
    }
  };
};

export const clearError = () => {
  return (dispatch: Dispatch<UserAction>, getState: () => RootState) => {
    dispatch({
      type: ActionType.USER_LOGIN_CLEAR_ERROR,
    });
  };
};

export const logoutUser = () => {
  return (dispatch: Dispatch<UserAction>, getState: () => RootState) => {
    dispatch({
      type: ActionType.USER_LOGOUT,
    });
    localStorage.removeItem('userInfo');
  };
};
