import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Grid, Typography } from '@material-ui/core';
import { BiPhoneCall } from 'react-icons/bi';
import { FiMail, FiMapPin } from 'react-icons/fi';
import { FaFacebookSquare, FaInstagramSquare, FaTwitterSquare, FaFax } from 'react-icons/fa';

const useStyles = makeStyles((theme: Theme) => ({
  footerWrapper: {
    backgroundColor: theme.palette.common.blueFooter,

    paddingLeft: '12em',
    paddingRight: '12em',
    paddingTop: '2em',
    paddingBottom: '2em',

    [theme.breakpoints.down('md')]: {
      paddingLeft: '3em',
      paddingRight: '3em',
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: '1em',
      paddingBottom: '1em',
    },
  },
  columnWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start !important',
    // alignItems: 'center',
    padding: '1.5em',

    color: 'rgba(255,255,255,0.7)',

    '& h6': {
      fontWeight: 700,
      paddingBottom: '1em',
      color: '#FFF',
    },

    // '& p div': {
    //   paddingTop: '0.50rem',
    //   paddingBottom: '0.15rem',
    // },
  },
  boldLabel: {
    fontWeight: 900,
  },
  businessHoursRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0.35rem',
  },
  contactUsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0.35rem',

    '& svg': {
      paddingTop: '0.25rem',
      paddingRight: '10px',
    },
  },
  followUsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '0.35rem',

    '& svg': {
      width: '2em',
      height: '2em',

      padding: '0.5em',

      cursor: 'pointer',
    },
  },
}));

const Footer: React.FC = () => {
  const classes = useStyles();

  return (
    <Container disableGutters maxWidth='xl'>
      <Grid container direction='row' className={classes.footerWrapper}>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} className={classes.columnWrapper}>
          <Typography variant='h6'>Business Hours</Typography>
          <Typography>
            <div className={classes.businessHoursRow}>
              <span className={classes.boldLabel}>Monday - Friday:</span>&nbsp;&nbsp;<span>8am - 6pm</span>
            </div>
            <div className={classes.businessHoursRow}>
              <span className={classes.boldLabel}>Saturday &amp; Sunday:</span>&nbsp;&nbsp;<span>Closed</span>
            </div>
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} className={classes.columnWrapper}>
          <Typography variant='h6'>Contact Us</Typography>
          <Typography>
            <div className={classes.contactUsRow}>
              <BiPhoneCall />
              <div>(202) 438-6035</div>
            </div>
            <div className={classes.contactUsRow}>
              <FaFax />
              <div>(301) 588-6066 (fax)</div>
            </div>
            <div className={classes.contactUsRow}>
              <FiMail />
              <div>kinghomeworks@gmail.com</div>
            </div>
            <div className={classes.contactUsRow}>
              <FiMapPin />
              <div>
                14002 Forest Ridge Drive
                <br />
                North Potomac, MD, 20878
                <br />
                USA
              </div>
            </div>
          </Typography>
        </Grid>
        <Grid item container xs={12} sm={12} md={4} lg={4} xl={4} className={classes.columnWrapper}>
          <Typography variant='h6'>Follow Us</Typography>
          <Typography>
            <div className={classes.followUsRow}>Visit us on the following social media sites:</div>
            <div className={classes.followUsRow}>
              <FaFacebookSquare onClick={() => alert('Under construction, try visiting soon.')} />
              <FaInstagramSquare onClick={() => alert('Under construction, try visiting soon.')} />
              <FaTwitterSquare onClick={() => alert('Under construction, try visiting soon.')} />
            </div>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
