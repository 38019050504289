import React, { useEffect, useState, useRef } from 'react';
import Header from './../components/Header';
import Footer from './../components/Footer';
import { useScrollData } from 'scroll-data-hook';
import SectionContainer from './SectionContainer';
import HomeSection from './HomeSection';
import ServicesSection from './ServicesSection';
import GallerySection from './GallerySection';
import TopSectionSensor from './../components/TopSectionSensor';
import Fab from '@material-ui/core/Fab';
import NavigationIcon from '@material-ui/icons/Navigation';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from './../components/Header';
import { useTypedSelector } from './../hooks/useTypedSelector';
import { useSectionAllActions } from './../hooks/useActions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles/colorManipulator'; /* https://stackoverflow.com/questions/47268652/jss-how-to-change-opacity-for-a-color */
import Loader from './../components/Loader';

export const MENU_SECTION_ITEMS = [
  {
    label: 'Home',
    code: 'home',
    section: HomeSection,
    bgColor: '#FFF',
  },
  {
    label: 'Services',
    code: 'services',
    section: ServicesSection,
    bgColor: '#FFF',
  },
  {
    label: 'Gallery',
    code: 'gallery',
    section: GallerySection,
    bgColor: '#f5f5f5',
  },
  /*
  {
    label: 'Why Us',
    code: 'why-us',
    bgColor: '#FFF',
  },
  {
    label: 'Reviews',
    code: 'reviews',
    bgColor: '#FFF',
  },
  {
    label: 'About',
    code: 'about',
    bgColor: '#FFF',
  },
  {
    label: 'Projects',
    code: 'projects',
    bgColor: '#FFF',
  },
  {
    label: 'FAQS',
    code: 'faqs',
    bgColor: '#FFF',
  },
  {
    label: 'Contact',
    code: 'contact',
    bgColor: '#FFF',
  },
  */
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    sectionControllerWrapper: {
      position: 'absolute',
      left: 0,
      right: 0,
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.greenButton,
      '&:hover': {
        backgroundColor: darken(theme.palette.common.greenButtonHover, 0.1),
      },
    },
  };
});

const MainPage: React.FC = () => {
  const classes = useStyles();
  const [visibleSections, setVisibleSections] = useState<string[]>([]);
  const [selectedTabValue, setSelectedTabValue] = useState<number>(0);
  const [headerTabMenuValue, setHeaderTabMenuValue] = useState<number | null>(0);
  const [showToTopButton, setShowToTopButton] = useState<boolean>(false);
  const visibilitySensorIntervalDelay = 250; // 0.25 second

  const { loading } = useTypedSelector((state) => state.sections.all);

  const { fetchSectionAll } = useSectionAllActions();

  const refHeaderTabMenuValue = useRef<number | null>(null);

  const { scrolling: isScrolling } = useScrollData({
    onScrollStart: () => {
      setHeaderTabMenuValue(refHeaderTabMenuValue.current);
    },
    onScrollEnd: () => {
      refHeaderTabMenuValue.current = null;
    },
  });

  useEffect(() => {
    fetchSectionAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isScrolling) {
      if (headerTabMenuValue !== null && headerTabMenuValue > -1) {
        setSelectedTabValue(headerTabMenuValue);
      } else {
        const sortedVisibleSections = visibleSections.sort();
        if (sortedVisibleSections.length > 0) {
          const index = sortedVisibleSections[0].split('-', 2)[0];
          setSelectedTabValue(Number(index) - 1);
        }
      }
    }
  }, [isScrolling, visibleSections, headerTabMenuValue]);

  const onHeaderTabMenuClicked = (tabValue: number) => {
    refHeaderTabMenuValue.current = tabValue;
    setHeaderTabMenuValue(tabValue);
  };

  // const timesRepeated = 2000;

  const renderPage = (props: any): JSX.Element => {
    const { section } = props;

    return React.createElement(section);
  };

  return (
    <>
      <Header selectedTabValue={selectedTabValue} onHeaderTabMenuClicked={onHeaderTabMenuClicked} />
      <Loader loading={loading} />
      {!loading && (
        <div className={classes.sectionControllerWrapper}>
          <TopSectionSensor setShowToTopButton={setShowToTopButton} />
          <SectionContainer
            index={1}
            sectionCode={MENU_SECTION_ITEMS[0].code}
            visibleSections={visibleSections}
            setVisibleSections={setVisibleSections}
            visibilitySensorIntervalDelay={visibilitySensorIntervalDelay}
            bgColor={MENU_SECTION_ITEMS[0].bgColor}
          >
            {renderPage(MENU_SECTION_ITEMS[0])}
          </SectionContainer>
          <SectionContainer
            index={2}
            sectionCode={MENU_SECTION_ITEMS[1].code}
            visibleSections={visibleSections}
            setVisibleSections={setVisibleSections}
            visibilitySensorIntervalDelay={visibilitySensorIntervalDelay}
            bgColor={MENU_SECTION_ITEMS[1].bgColor}
          >
            {renderPage(MENU_SECTION_ITEMS[1])}
          </SectionContainer>
          <SectionContainer
            index={3}
            sectionCode={MENU_SECTION_ITEMS[2].code}
            visibleSections={visibleSections}
            setVisibleSections={setVisibleSections}
            visibilitySensorIntervalDelay={visibilitySensorIntervalDelay}
            bgColor={MENU_SECTION_ITEMS[2].bgColor}
          >
            {renderPage(MENU_SECTION_ITEMS[2])}
          </SectionContainer>
          <Footer />
          {showToTopButton && (
            <Fab
              className={classes.fab}
              component={HashLink}
              to='#'
              elementId={''}
              scroll={(el: HTMLElement) => scrollWithOffset(el, -90)}
            >
              <NavigationIcon />
            </Fab>
          )}
        </div>
      )}
    </>
  );
};

export default MainPage;
