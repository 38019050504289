import React, { useReducer, useState, useEffect } from 'react';
import { Card, CardHeader, CardContent, Tabs, Tab, Button } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useServicesContext, ServicesDataItems } from './../../../contexts/ServicesContext';
import ServicesItem from './ServicesItem';
import _ from 'lodash';

interface TabPanelsProps {
  tabValue: number;
}

interface ServicesTabsProps {
  initialServicesData: ServicesDataItems;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    servicesTitle: {
      fontSize: '1rem',
    },
    tabButtonNormal: {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: '5px',
      borderTopRightRadius: '5px',
      minWidth: '5em !important',
    },
    tabButtonSelected: {
      backgroundColor: theme.palette.grey[100],
    },
    tabpanelContainer: {
      backgroundColor: theme.palette.grey[100],
    },
    tabIndicator: {
      backgroundColor: theme.palette.grey[100],
    },
    tabButtonNew: {
      marginLeft: '12px',
      marginTop: '7px',
      height: '2rem',
    },
  };
});

const ServicesTabs: React.FC<ServicesTabsProps> = ({ initialServicesData = [] }) => {
  const classes = useStyles();
  const [tabValue, setTabValue] = useState<number>(0);
  const SERVICES_LIMIT = 8;

  const TabButtons = ({ tabValue }: TabPanelsProps): JSX.Element => {
    // below code was taken from https://reactjs.org/docs/hooks-faq.html#is-there-something-like-forceupdate
    // TODO: study about 'useReducer' more in detail, for now just use this snippet to force rerender
    const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

    const { servicesData, setServicesData } = useServicesContext();

    // to set the tabValue when user 'deletes' a tab
    useEffect(() => {
      if (!_.isEmpty(servicesData) && tabValue === servicesData?.length) {
        setTabValue(tabValue - 1);
      }
    }, [servicesData, tabValue]);

    const isNewButtonDisabled = () => {
      return servicesData && !_.isEmpty(servicesData) && servicesData.length >= SERVICES_LIMIT ? true : false;
    };

    const NewButton = (): JSX.Element => {
      return (
        <Button
          variant='contained'
          disabled={isNewButtonDisabled()}
          color='primary'
          size='small'
          onClick={() => {
            const newServicesData = servicesData && servicesData.length > 0 ? servicesData : [];
            newServicesData.push({
              title: '',
              subTitle: '',
              description: '',
              icon: '',
              imageUrl: 'https://',
            });
            setServicesData(newServicesData);
            forceUpdate();
            if (servicesData?.length) {
              setTabValue(servicesData.length - 1);
            }
          }}
          className={classes.tabButtonNew}
        >
          New
        </Button>
      );
    };

    return (
      <>
        <Tabs
          value={tabValue}
          classes={{
            indicator: classes.tabIndicator,
          }}
        >
          {servicesData?.map((servicesDataItem, index) => (
            <Tab
              key={index + 1}
              label={index + 1}
              onClick={() => setTabValue(index)}
              classes={{
                root: classes.tabButtonNormal,
                selected: classes.tabButtonSelected,
              }}
            />
          ))}
          <Tab component={NewButton} />
        </Tabs>
      </>
    );
  };

  const TabPanels = ({ tabValue }: TabPanelsProps): JSX.Element => {
    const { setServicesData, servicesData } = useServicesContext();

    useEffect(() => {
      if (!servicesData) {
        setServicesData(initialServicesData);
      }
    }, [servicesData, setServicesData]);

    return (
      <div className={classes.tabpanelContainer}>
        {servicesData?.map((servicesDataItem, index) => {
          return <ServicesItem key={index} index={index} tabValue={tabValue} />;
        })}
      </div>
    );
  };

  return (
    <Card variant='outlined'>
      <CardHeader
        title='Services'
        classes={{
          title: classes.servicesTitle,
        }}
      />
      <CardContent>
        <TabButtons tabValue={tabValue} />
        <TabPanels tabValue={tabValue} />
      </CardContent>
    </Card>
  );
};

export default ServicesTabs;
