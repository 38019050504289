import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './state';
import PublicMainPage from './pages/MainPage';
import AdminMainPage from './pages/admin/MainPage';
// import OtherPage from './pages/admin/Other';
import HomeSection from './pages/admin/HomeSection';
import ServicesSection from './pages/admin/ServicesSection';

import LoginPage from './pages/admin/LoginPage';
import { ThemeProvider } from '@material-ui/styles';
import theme from './Theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <Switch>
            {/* Private Admin Routes */}
            <Route path='/admin' exact component={AdminMainPage} />
            <Route path='/admin/section/home' component={HomeSection} />
            <Route path='/admin/section/services' component={ServicesSection} />

            {/* Public Website Route */}
            <Route path='/login' component={LoginPage} />
            {/* we could also put this on top but need 'exact' property */}
            <Route path='/' component={PublicMainPage} />
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
};

export default App;
