import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  userActionCreators,
  sectionAllActionCreators,
  sectionHomeActionCreators,
  sectionServicesActionCreators,
} from './../state';

/**
 * These are custom hooks
 */

export const useUserActions = () => {
  const dispatch = useDispatch();

  /* the below code is returning an object of actionCreators but each is wrapped in dispatch;
     {
       searchRepositories: dispatch(searchRepositories),
       xxx: dispatch(xxx),
     }
  */

  return bindActionCreators(userActionCreators, dispatch);
};

export const useSectionAllActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(sectionAllActionCreators, dispatch);
};

export const useSectionHomeActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(sectionHomeActionCreators, dispatch);
};

export const useSectionServicesActions = () => {
  const dispatch = useDispatch();
  return bindActionCreators(sectionServicesActionCreators, dispatch);
};
