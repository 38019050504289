import { createTheme } from '@material-ui/core/styles';

// Override Mui's theme typings to include the new property under theme.palette
// https://www.bergqvist.it/blog/2020/6/26/extending-theme-material-ui-with-typescript
declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors {
    blueHeader: string;
    blueButton: string;
    blueButtonHover: string;
    blueIcon: string;
    blueBgLight: string;
    blueBgDark: string;
    blueForm: string;
    blueFooter: string;
    greenButton: string;
    greenButtonHover: string;
    adminGreenHeader: string;
    adminGreenButton: string;
    adminGreenButtonHover: string;
    adminGreenIcon: string;
    adminGreenBgLight: string;
    adminGreenBgDark: string;
    adminGreenForm: string;
    adminGreenFooter: string;
    adminYellowButton: string;
    adminYellowButtonHover: string;
    red: string;
  }
}

// simple color constants for public facing pages
// Blue + Green combo
const blueHeader = '#2164B7';
const blueButton = '#5972D6';
const blueButtonHover = '#2270CD';
const blueIcon = '#2F7DDB';
const blueBgLight = '#2F7DDB';
const blueBgDark = '#1E59A2';
const blueForm = '#2B70C6';
const blueFooter = '#113560';
const greenButton = '#6FCB65';
const greenButtonHover = '#62b158';

// simple color constants for private (admin) pages
// Green / Yellow combo
const adminGreenHeader = '#409041';
const adminGreenButton = '#77B45A';
const adminGreenButtonHover = '#62B158';
const adminGreenIcon = '##49A149';
const adminGreenBgLight = '##53B153';
const adminGreenBgDark = '#397E39';
const adminGreenForm = '#4B9F4C';
const adminGreenFooter = '#214A21';
const adminYellowButton = '#E8B357';
const adminYellowButtonHover = '#E6A845';

const red = '#FF0000';

/**
 * createTheme - creates an instance of muiTheme and overwrites the default theme with
 * the options that you pass.
 */
const theme = createTheme({
  palette: {
    common: {
      blueHeader: `${blueHeader}`,
      blueButton: `${blueButton}`,
      blueButtonHover: `${blueButtonHover}`,
      blueIcon: `${blueIcon}`,
      blueBgLight: `${blueBgLight}`,
      blueBgDark: `${blueBgDark}`,
      blueForm: `${blueForm}`,
      blueFooter: `${blueFooter}`,
      greenButton: `${greenButton}`,
      greenButtonHover: `${greenButtonHover}`,
      adminGreenHeader: `${adminGreenHeader}`,
      adminGreenButton: `${adminGreenButton}`,
      adminGreenButtonHover: `${adminGreenButtonHover}`,
      adminGreenIcon: `${adminGreenIcon}`,
      adminGreenBgLight: `${adminGreenBgLight}`,
      adminGreenBgDark: `${adminGreenBgDark}`,
      adminGreenForm: `${adminGreenForm}`,
      adminGreenFooter: `${adminGreenFooter}`,
      adminYellowButton: `${adminYellowButton}`,
      adminYellowButtonHover: `${adminYellowButtonHover}`,
      red: `${red}`,
    },
    primary: {
      main: `${blueIcon}`,
    },
    secondary: {
      main: `${greenButton}`,
    },
  },
  typography: {
    fontFamily: ['"Lato"', 'sans-serif'].join(','),
  },
});

export default theme;
