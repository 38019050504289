import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { useTypedSelector } from './../../hooks/useTypedSelector';
import { getThumbnailUrl } from './../../utility';

interface GalleryPanelProps {
  setSelectedGallery: React.Dispatch<React.SetStateAction<string | null>>;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    rootGalleryPanel: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    container: {
      position: 'relative',
      maxHeight: '200px',
      margin: '7px',
      borderStyle: 'solid',
      borderWidth: '0.05em',
      borderColor: 'grey',
      boxShadow: '4px 4px 4px grey',
    },
    middleOn: {
      transition: '.5s ease',
      opacity: 1,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '-ms-transform': 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
    middleOff: {
      transition: '.5s ease',
      opacity: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      '-ms-transform': 'translate(-50%, -50%)',
      pointerEvents: 'none',
    },
    image: {
      opacity: 1,
      transition: '.5s ease',
      backfaceVisibility: 'hidden',

      '&:hover': {
        opacity: 0.2,
        cursor: 'pointer',
      },
    },
    galleryLabel: {
      fontSize: '1.25rem',
      fontWeight: 'bold',
      textAlign: 'center',
    },
  };
});

const GalleryPanel: React.FC<GalleryPanelProps> = ({ setSelectedGallery }) => {
  const [hoveredImageGalleryCode, setHoveredImageGalleryCode] = useState<string | null>(null);

  const classes = useStyles();
  const { loading, error, content } = useTypedSelector((state) => state.sections.all);

  const onMouseEnterHandler = (code: string) => (evt: React.MouseEvent<HTMLDivElement>) => {
    setHoveredImageGalleryCode(code);
  };

  const onMouseLeaveHandler = (code: string) => (evt: React.MouseEvent<HTMLDivElement>) => {
    setHoveredImageGalleryCode(null);
  };

  const onClickHandler = (code: string) => (evt: React.MouseEvent<HTMLDivElement>) => {
    // alert(code);
    setSelectedGallery(code);
  };

  if (!loading && content && content.gallery) {
    const { gallery } = content.gallery.content;

    return (
      <div className={classes.rootGalleryPanel}>
        {gallery.map(({ code, images }) => {
          const thumbnailUrl = getThumbnailUrl(images[0].url);
          let classForMiddle = classes.middleOff;
          if (code === hoveredImageGalleryCode) {
            classForMiddle = classes.middleOn;
          }

          return (
            <div
              className={classes.container}
              onMouseEnter={onMouseEnterHandler(code)}
              onMouseLeave={onMouseLeaveHandler(code)}
              onClick={onClickHandler(code)}
              key={code}
            >
              <img src={thumbnailUrl} alt={code} className={classes.image} />
              <div className={classForMiddle}>
                <Typography className={classes.galleryLabel}>Gallery {code}</Typography>
              </div>
            </div>
          );
        })}
      </div>
    );
  } else {
    return null;
  }
};

export default GalleryPanel;
