import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Grid,
  TextField,
  Box,
  Button,
  Snackbar,
  Card,
  CardContent,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { useServicesContext, ServicesDataItem } from './../../../contexts/ServicesContext';
import { makeStyles, Theme } from '@material-ui/core/styles';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useSectionServicesActions } from './../../../hooks/useActions';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { formats, modules } from '.';
import { darken } from '@material-ui/core/styles/colorManipulator';

interface ServicesItemProps {
  tabValue: number;
  index: number;
}

const iconMenuItems = [
  {
    code: 'IoRestaurantOutline',
    description: 'Restaurant (outline)',
  },
  {
    code: 'FaBath',
    description: 'Bath 1 (solid)',
  },
  {
    code: 'BiBath',
    description: 'Bath 2 (outline)',
  },
  {
    code: 'GiBathtub',
    description: 'Bath Tub (semi-solid)',
  },
  {
    code: 'GiPaintBucket',
    description: 'Paint Bucket (semi-solid)',
  },
  {
    code: 'GoPaintcan',
    description: 'Paint Can (semi-solid)',
  },
  {
    code: 'GiBrickWall',
    description: 'Brick Wall (solid)',
  },
  {
    code: 'IoHammerOutline',
    description: 'Hammer (outline)',
  },
  {
    code: 'BiCabinet',
    description: 'Cabinet (outline)',
  },
  {
    code: 'FaUmbrellaBeach',
    description: 'Umbrella Beach (solid)',
  },
  {
    code: 'GiParkBench',
    description: 'Park Bench (solid)',
  },
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    textField: {
      backgroundColor: theme.palette.common.white,
    },
    selectFieldRoot: {
      backgroundColor: theme.palette.common.white,

      '&:focus': {
        backgroundColor: theme.palette.common.white,
      },
    },
    fileInput: {
      display: 'none',
    },
    selectImageBtnContainer: {
      display: 'flex',
      width: '100%',
    },
    imageUrlRoot: {
      backgroundColor: theme.palette.common.white,

      '& div.Mui-disabled': {
        color: theme.palette.common.black,
      },

      '& label.MuiFormLabel-root': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    successAlert: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    reactQuillDiv: {
      '& .ql-editor': {
        minHeight: '7rem',
        maxHeight: '7rem',
      },
    },
    deleteBtn: {
      backgroundColor: theme.palette.common.red,
      color: theme.palette.getContrastText(theme.palette.common.red),

      '&:hover': {
        backgroundColor: darken(theme.palette.common.red, 0.25),
      },
    },
  };
});

// TODO: check later, need to be reusable
const Alert = (props: AlertProps) => {
  const classes = useStyles();
  return <MuiAlert elevation={4} variant='filled' {...props} classes={{ filledSuccess: classes.successAlert }} />;
};

const ServicesItem: React.FC<ServicesItemProps> = ({ tabValue, index }) => {
  const classes = useStyles();
  const quillRef = useRef(null);
  const [title, setTitle] = useState<string>(' ');
  const [subTitle, setSubTitle] = useState<string>(' ');
  const [description, setDescription] = useState<string>(' ');
  const [icon, setIcon] = useState<string>(' ');
  const [imageUrl, setImageUrl] = useState<string>(' ');
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { uploadImageSectionServices, uploadImageReset } = useSectionServicesActions();
  const { servicesData, setServicesData } = useServicesContext();

  const { loading, url, error } = useTypedSelector((state) => state.sections.services.uploadImage);

  useEffect(() => {
    if (servicesData && servicesData.length > 0) {
      setTitle(servicesData[index].title);
      setSubTitle(servicesData[index].subTitle);
      setDescription(servicesData[index].description);
      setIcon(servicesData[index].icon);
      setImageUrl(servicesData[index].imageUrl);
    }
  }, [servicesData, index]);

  useEffect(() => {
    if (!loading && url && servicesData) {
      setShowSuccess(true);
      setImageUrl(url);
      setServicesData(
        servicesData.map((data: ServicesDataItem, idx: number) => {
          if (idx === tabValue) {
            return {
              ...data,
              imageUrl: url,
            };
          }
          return data;
        })
      );
      uploadImageReset();
    }
  }, [url, loading, uploadImageReset, setServicesData, servicesData, tabValue]);

  useEffect(() => {
    if (error && !loading) {
      setShowError(true);
    }
  }, [loading, error]);

  const uploadImage = (evt: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = evt.target;

    // just make sure 1 file is uploaded.
    if (!files || files.length !== 1 || !files[0].type.includes('image')) {
      return;
    }

    uploadImageSectionServices(files[0]);
  };

  const alertCloseHandler = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  // source: https://github.com/zenoamaro/react-quill/issues/391
  const handleQuillRef = useCallback((ref) => {
    if (ref) {
      const quill = ref.getEditor();
      quill.root.setAttribute('spellcheck', 'false'); // instead of boolean, i passed string 'false' to make it work
      // ref.focus();
      quillRef.current = ref;
    }
  }, []);

  const handleReactQuillChange = (_servicesData: typeof servicesData) => (richText: any) => {
    // TODO: validate value before setting

    setDescription(richText);
    if (setServicesData && _servicesData) {
      setServicesData(
        _servicesData.map((data: ServicesDataItem, idx: number) => {
          if (idx === index) {
            return {
              ...data,
              description: richText,
            };
          }
          return data;
        })
      );
    }
  };

  const deleteHandler = (evt: React.MouseEvent<HTMLButtonElement>) => {
    const _servicesData = servicesData?.filter((data: ServicesDataItem, idx: number) => {
      return idx !== index ? true : false;
    });

    if (_servicesData) {
      setServicesData(_servicesData);
    }
  };

  return (
    servicesData && (
      <>
        <Snackbar
          open={showError || showSuccess}
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          autoHideDuration={5000}
          onClose={() => alertCloseHandler()}
        >
          <>
            {error ? (
              <Alert severity='error' onClose={() => alertCloseHandler()}>
                {error}
              </Alert>
            ) : (
              <Alert severity='success' onClose={() => alertCloseHandler()}>
                Image uploaded successfully.
              </Alert>
            )}
          </>
        </Snackbar>
        <Box p={3} hidden={index !== tabValue}>
          <Grid container item direction='row' spacing={3}>
            <Grid item container xs={12} direction='row' justifyContent='flex-end'>
              <Button
                variant='contained'
                size='small'
                className={classes.deleteBtn}
                onClick={deleteHandler}
                disabled={servicesData.length === 1}
              >
                Delete
              </Button>
            </Grid>
            <Grid item xs={9}>
              <TextField
                // autoFocus : TODO.. use this
                error={false}
                id='title'
                label='Title'
                helperText=''
                variant='outlined'
                fullWidth
                value={title}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setTitle(evt.target.value);
                  if (setServicesData) {
                    setServicesData(
                      servicesData.map((data: ServicesDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            title: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth variant='outlined'>
                <InputLabel id='icon-label'>Icon</InputLabel>
                <Select
                  labelId='icon-label'
                  id='icon'
                  value={icon}
                  label='Icon'
                  classes={{
                    root: classes.selectFieldRoot,
                  }}
                  onChange={(evt: React.ChangeEvent<{ value: any }>) => {
                    setIcon(evt.target.value);
                    if (setServicesData) {
                      setServicesData(
                        servicesData.map((data: ServicesDataItem, idx: number) => {
                          if (idx === index) {
                            return {
                              ...data,
                              icon: evt.target.value,
                            };
                          }
                          return data;
                        })
                      );
                    }
                  }}
                >
                  {iconMenuItems.map((icon) => (
                    <MenuItem value={icon.code}>{icon.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={false}
                id='subTitle'
                label='SubTitle'
                helperText=''
                variant='outlined'
                fullWidth
                value={subTitle}
                className={classes.textField}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setSubTitle(evt.target.value);
                  if (setServicesData) {
                    setServicesData(
                      servicesData.map((data: ServicesDataItem, idx: number) => {
                        if (idx === index) {
                          return {
                            ...data,
                            subTitle: evt.target.value,
                          };
                        }
                        return data;
                      })
                    );
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Card variant='outlined'>
                <CardContent>
                  <Typography>Description</Typography>
                  <br />
                  <ReactQuill
                    ref={handleQuillRef}
                    value={description}
                    modules={modules}
                    formats={formats}
                    onChange={handleReactQuillChange(servicesData)}
                    className={classes.reactQuillDiv}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={9}>
              <TextField
                error={false}
                contentEditable={false}
                id='image-url'
                label='Image Url'
                helperText=''
                variant='outlined'
                fullWidth
                value={imageUrl}
                disabled={true}
                classes={{
                  root: classes.imageUrlRoot,
                }}
              />
            </Grid>
            <Grid item container xs={3}>
              <input
                accept='image/*'
                className={classes.fileInput}
                id='contained-button-file'
                type='file'
                onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
                  uploadImage(evt);
                }}
              />
              <label htmlFor='contained-button-file' className={classes.selectImageBtnContainer}>
                <Button variant='contained' color='default' component='span' fullWidth>
                  Upload Image
                </Button>
              </label>
            </Grid>
          </Grid>
        </Box>
      </>
    )
  );
};

export default ServicesItem;
