import axios from 'axios';
import { Dispatch } from 'redux';
import { ActionType } from './../action-types';
import { SectionHomeAction } from './../actions';
import { RootState } from './../index';
import { ContentHome } from './../typings';

export const fetchSectionHome = () => {
  return async (dispatch: Dispatch<SectionHomeAction>, getState: () => RootState) => {
    try {
      dispatch({ type: ActionType.SECTION_HOME_FETCH_REQUEST });

      const {
        data: { content },
      } = await axios.get(`${process.env.REACT_APP_NODEJS_API_URL}/api/sections/home`);

      dispatch({
        type: ActionType.SECTION_HOME_FETCH_SUCCESS,
        payload: content,
      });
    } catch (error: any) {
      let errorMessage = 'unknown error';
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      dispatch({
        type: ActionType.SECTION_HOME_FETCH_FAIL,
        payload: errorMessage,
      });
    }
  };
};

export const updateSectionHome = (contentHome: ContentHome) => {
  return async (dispatch: Dispatch<SectionHomeAction>, getState: () => RootState) => {
    try {
      dispatch({ type: ActionType.SECTION_HOME_UPDATE_REQUEST });

      const { userLogin } = getState();

      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userLogin.userInfo?.token}`,
        },
      };

      const { data }: { data: ContentHome } = await axios.put(
        `${process.env.REACT_APP_NODEJS_API_URL}/api/sections/home`,
        contentHome,
        config
      );

      dispatch({
        type: ActionType.SECTION_HOME_UPDATE_SUCCESS,
        payload: data,
      });
    } catch (error: any) {
      let errorMessage = 'unknown error';
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      dispatch({
        type: ActionType.SECTION_HOME_UPDATE_FAIL,
        payload: errorMessage,
      });
    }
  };
};

export const uploadImageSectionHome = (file: File) => {
  return async (dispatch: Dispatch<SectionHomeAction>, getState: () => RootState) => {
    try {
      dispatch({ type: ActionType.SECTION_HOME_UPLOAD_IMAGE_REQUEST });

      const formData = new FormData();
      formData.append('image', file);

      const { userLogin } = getState();

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${userLogin.userInfo?.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_NODEJS_API_URL}/api/upload/s3/carousel`,
        formData,
        config
      );

      if (data.message) {
        // means that there was an upload error, even if  the response.statucode was 200
        dispatch({ type: ActionType.SECTION_HOME_UPLOAD_IMAGE_FAIL, payload: data.mesage });
      } else {
        dispatch({ type: ActionType.SECTION_HOME_UPLOAD_IMAGE_SUCCESS, payload: data });
      }
    } catch (error: any) {
      dispatch({ type: ActionType.SECTION_HOME_UPLOAD_IMAGE_FAIL, payload: error.response.data.message });
    }
  };
};

export const uploadImageReset = () => async (dispatch: Dispatch<SectionHomeAction>, getState: () => RootState) => {
  dispatch({ type: ActionType.SECTION_HOME_UPLOAD_IMAGE_RESET });
};
