import React from 'react';
import { Container, Grid, Typography, Button } from '@material-ui/core';
import { useTypedSelector } from './../hooks/useTypedSelector';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/styles';
import { BiPhoneCall } from 'react-icons/bi';
import { FaHome } from 'react-icons/fa';
import { FiClock } from 'react-icons/fi';
import Carousel from 'react-material-ui-carousel';
import { darken } from '@material-ui/core/styles/colorManipulator'; /* https://stackoverflow.com/questions/47268652/jss-how-to-change-opacity-for-a-color */
import { Carousel as CarouselData } from './../state/typings';
import { HashLink } from 'react-router-hash-link';
import { scrollWithOffset } from './../components/Header';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MENU_SECTION_ITEMS } from './MainPage';

const useStyles = makeStyles((theme: Theme) => {
  return {
    bannerWrapper: {
      paddingTop: '2em',
      paddingBottom: '2em',
      paddingLeft: '12em',
      paddingRight: '12em',

      [theme.breakpoints.down('md')]: {
        paddingLeft: '3em',
        paddingRight: '3em',
      },

      [theme.breakpoints.down('sm')]: {
        paddingTop: '1.5em',
        paddingBottom: '1.5em',
      },
    },
    bannerTitle: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
        paddingBottom: '1em',
      },
    },
    bannerTitleIcon: {
      width: '3.250em',
      height: '3.250em',
      marginRight: '10px',
      color: theme.palette.common.blueIcon,
    },
    bannerContactTime: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',

      '& p:nth-child(1)': {
        fontWeight: 'bold',
      },
      '& p:nth-child(2)': {
        fontSize: '0.85rem',
        color: theme.palette.grey[600],
      },

      [theme.breakpoints.down('md')]: {
        justifyContent: 'center',
      },

      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    bannerContactTimeIcon: {
      width: '2.50em',
      height: '2.50em',
      marginRight: '10px',
      color: theme.palette.common.blueIcon,
    },
    carouselItem: {
      height: '560px',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      width: '100%',

      '& div.text-center': {
        position: 'absolute',
        top: '100px',
        width: '100%',
        textAlign: 'center',

        '& div': {
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '35em',
            paddingRight: '35em',
          },

          [theme.breakpoints.down('lg')]: {
            paddingLeft: '25em',
            paddingRight: '25em',
          },

          [theme.breakpoints.down('md')]: {
            paddingLeft: '20em',
            paddingRight: '20em',
          },

          [theme.breakpoints.down('sm')]: {
            paddingLeft: '3em',
            paddingRight: '3em',
          },

          '& h3': {
            color: 'white',
            fontWeight: 900,
            paddingBottom: '15px',

            [theme.breakpoints.down('md')]: {
              fontSize: '3em',
            },

            [theme.breakpoints.down('sm')]: {
              fontSize: '2.25em',
            },
          },
          '& p': {
            color: 'white',
            fontSize: '1.25em',
            paddingBottom: '15px',

            [theme.breakpoints.down('md')]: {
              fontSize: '1em',
            },
          },
        },
      },
    },
    buttonContainedSecondary: {
      textTransform: 'none',
      fontWeight: 900,
      fontSize: '1.20rem',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.greenButton,
      '&:hover': {
        backgroundColor: darken(theme.palette.common.greenButtonHover, 0.1),
      },
    },
  };
});

const renderCarouselItems = (carouselData: [CarouselData], classes: ClassNameMap, heightToOffset: number) => {
  return carouselData.map(({ title, imageUrl, description, buttonLink, buttonText }) => {
    let elementId = null;
    if (buttonLink && buttonText) {
      const index = MENU_SECTION_ITEMS.findIndex(({ code }) => code === buttonLink);
      elementId = `${index + 1}-${buttonLink}`;
    }

    return (
      <div
        className={classes.carouselItem}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      >
        <div className='text-center'>
          <div>
            <Typography variant='h3'>{title}</Typography>
            <Typography variant='body1'>{description}</Typography>
            {buttonLink && buttonText && (
              <Button
                component={HashLink}
                to=''
                elementId={String(elementId)}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                variant='contained'
                size='large'
                color='secondary'
                classes={{
                  containedSecondary: classes.buttonContainedSecondary,
                }}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  });
};

const HomeSection: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const heightToOffset = isExtraSmall ? -64 : -70; // also in Header.tsx

  const { loading, error, content } = useTypedSelector((state) => state.sections.all);

  if (!loading && content && content.home) {
    const { title, phone, email, workHours, workDays, carousel } = content.home.content;

    return (
      <Container disableGutters maxWidth='xl'>
        <Grid container direction='row' className={classes.bannerWrapper}>
          <Grid item container xl={8} lg={6} md={6} sm={12} xs={12} className={classes.bannerTitle}>
            <FaHome className={classes.bannerTitleIcon} />
            <Typography variant='h4' style={{ fontWeight: 900 }}>
              {title}
            </Typography>
          </Grid>
          <Grid item container xl={2} lg={3} md={3} sm={6} xs={12} className={classes.bannerContactTime}>
            <BiPhoneCall className={classes.bannerContactTimeIcon} />
            <div>
              <Typography>{phone}</Typography>
              <Typography>{email}</Typography>
            </div>
          </Grid>
          <Grid item container xl={2} lg={3} md={3} sm={6} xs={12} className={classes.bannerContactTime}>
            <FiClock className={classes.bannerContactTimeIcon} />
            <div>
              <Typography>{workHours}</Typography>
              <Typography>{workDays}</Typography>
            </div>
          </Grid>
        </Grid>
        {/* Carousel Area */}
        <Carousel>{renderCarouselItems(carousel, classes, heightToOffset)}</Carousel>
      </Container>
    );
  } else {
    return null;
  }
};

export default HomeSection;
