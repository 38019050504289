import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { ElevationScroll } from './../Header';
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useUserActions } from './../../hooks/useActions';
import { useTypedSelector } from './../../hooks/useTypedSelector';

export const ADMIN_PAGE_URLS = [
  {
    path: '/admin',
    tabValue: 0,
  },
  {
    path: '/admin/section/home',
    tabValue: 1,
  },
  {
    path: '/admin/section/services',
    tabValue: 2,
  },
];

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {
      backgroundColor: theme.palette.common.adminGreenHeader,
    },
    toolbarMargin: {
      ...theme.mixins.toolbar,
    },
    tabsRoot: {
      width: '100%',
    },
    tabsFlexContainer: {
      justifyContent: 'flex-start',
    },
    tab: {
      minWidth: '10px', // make the narrowest possible, then just add margins on the sides
      marginLeft: '25px',
      fontWeight: 600,
      color: 'rgba(255, 255, 255, 0.55)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.75)',
        fontWeight: 700,
      },
    },
    logoutTabRoot: {
      marginLeft: 'auto',
      marginRight: '25px',
    },
    selectedTab: {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.20)',
    },
  };
});

const Header: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [tabValue, setTabValue] = useState<number>(0);
  const { logoutUser } = useUserActions();

  const { userInfo } = useTypedSelector((state) => state.userLogin);

  useEffect(() => {
    const { pathname } = location;
    const adminUrl = ADMIN_PAGE_URLS.find((url) => url.path === pathname);
    const currTabValue = adminUrl?.tabValue;
    if (!Number.isNaN(currTabValue) && Number(currTabValue) > -1) {
      setTabValue(Number(currTabValue));
    }
  }, [location]);

  return (
    <>
      <ElevationScroll>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar disableGutters>
            <Tabs
              classes={{
                root: classes.tabsRoot,
                flexContainer: classes.tabsFlexContainer,
              }}
              value={tabValue}
              onChange={(event: React.ChangeEvent<{}>, value: any) => {
                if (value === 0) {
                  history.push('/admin');
                } else if (value === 1) {
                  history.push('/admin/section/home');
                } else if (value === 2) {
                  history.push('/admin/section/services');
                } else if (value === 99) {
                  logoutUser();
                  history.replace('/login');
                }
              }}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <Tab
                label='Main'
                className={classes.tab}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
                disabled={!userInfo || _.isEmpty(userInfo)}
                value={0}
              />
              <Tab
                label='Home'
                className={classes.tab}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
                disabled={!userInfo || _.isEmpty(userInfo)}
                value={1}
              />
              <Tab
                label='Services'
                className={classes.tab}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
                disabled={!userInfo || _.isEmpty(userInfo)}
                value={2}
              />
              {userInfo && Object.keys(userInfo).length > 0 && (
                <Tab
                  label='Logout'
                  className={classes.tab}
                  classes={{
                    root: classes.logoutTabRoot,
                    selected: classes.selectedTab,
                  }}
                  value={99}
                  disableRipple
                  disableFocusRipple
                  disabled={!userInfo || _.isEmpty(userInfo)}
                />
              )}
            </Tabs>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
    </>
  );
};

export default Header;
