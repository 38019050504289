import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router';
import { Container, Typography, Grid, TextField, Button, Snackbar } from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import Header from '../../components/admin/Header';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { useUserActions } from './../../hooks/useActions';
import { useTypedSelector } from './../../hooks/useTypedSelector';

const DEFAULT_ERROR_MESSAGE = 'Invalid email or password';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingTop: '2rem',
    },
    formRow: {
      padding: '1em',
    },
  };
});

// TODO: check later, need to be reusable
const Alert = (props: AlertProps) => {
  return <MuiAlert elevation={4} variant='filled' {...props} />;
};

const LoginPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const inputRef = useRef<HTMLInputElement>();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);

  const { loginUser, clearError } = useUserActions();

  const { loading, userInfo, error } = useTypedSelector((state) => state.userLogin);

  const setTextInputRef = (element: HTMLInputElement) => {
    inputRef.current = element;
  };

  useEffect(() => {
    inputRef.current?.focus();

    return () => {
      clearError();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userInfo) && !loading) {
      history.replace('/admin');
    }
  }, [history, userInfo, loading]);

  useEffect(() => {
    if (error && !loading) {
      setShowError(true);
    }
  }, [loading, error]);

  const errorCloseHandler = () => {
    setShowError(false);
  };

  const onClickHandler = (evt: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>): void => {
    if (!evt.isDefaultPrevented()) {
      evt.preventDefault();
    }

    /* if necessary, put manual validation here */

    loginUser(email, password);
  };

  return (
    <>
      <Snackbar
        open={showError}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        onClose={() => errorCloseHandler()}
      >
        <Alert severity='error' onClose={() => errorCloseHandler()}>
          {error}
        </Alert>
      </Snackbar>
      <Header />
      <Container maxWidth='xs' className={classes.container}>
        <form noValidate autoComplete='off'>
          <Grid container direction='column'>
            <Grid container direction='row' justify='center' item>
              <Typography variant='h4'>Login</Typography>
            </Grid>
            <Grid container direction='row' justify='center' item className={classes.formRow}>
              <TextField
                error={showError && error === DEFAULT_ERROR_MESSAGE}
                id='email'
                label='Email'
                // defaultValue='email@example.com'
                helperText=''
                variant='outlined'
                fullWidth
                value={email}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setEmail(evt.target.value);
                }}
                inputRef={setTextInputRef}
              />
            </Grid>
            <Grid container direction='row' justify='center' item className={classes.formRow}>
              <TextField
                error={showError && error === DEFAULT_ERROR_MESSAGE}
                id='password'
                type='password'
                label='Password'
                // defaultValue='password'
                helperText=''
                variant='outlined'
                fullWidth
                value={password}
                onChange={(evt: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                  setPassword(evt.target.value);
                }}
              />
            </Grid>
            <Grid item className={classes.formRow}>
              <Button variant='contained' fullWidth onClick={onClickHandler} disabled={loading} type='submit'>
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </>
  );
};

export default LoginPage;
