import React from 'react';
import { css } from '@emotion/react';
import ClockLoader from 'react-spinners/ClockLoader';

interface LoaderProps {
  loading: boolean;
}

const override = css`
  display: block;
  margin: 10px auto;
`;

const Loader: React.FC<LoaderProps> = ({ loading }) => {
  return <ClockLoader color='#2F7DDB' loading={loading} size={150} css={override} />;
};

export default Loader;
