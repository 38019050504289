import { SectionAllAction, SectionHomeAction, SectionServicesAction } from './../actions';
import { ActionType } from './../action-types';
import { ContentHome, ContentServices, SectionHome, SectionServices, SectionGallery } from './../typings';

/***** ALL (sections) ******/
interface AllContentState {
  loading: boolean;
  content: {
    home: SectionHome;
    services: SectionServices;
    gallery: SectionGallery;
  } | null;
  error: string | null;
}

/***** HOME ******/
interface ContentHomeFetchState {
  loading: boolean;
  content: ContentHome | null;
  error: string | null;
}

interface ContentHomeUpdateState {
  loading: boolean;
  content: ContentHome | null;
  error: string | null;
}

interface ContentHomeUploadImageState {
  loading: boolean;
  url: string | null;
  error: string | null;
}

interface HomeContentState {
  fetch: ContentHomeFetchState;
  update: ContentHomeUpdateState;
  uploadImage: ContentHomeUploadImageState;
}

/***** SERVICES ******/
interface ContentServicesFetchState {
  loading: boolean;
  content: ContentServices | null;
  error: string | null;
}

interface ContentServicesUpdateState {
  loading: boolean;
  content: ContentServices | null;
  error: string | null;
}

interface ContentServicesUploadImageState {
  loading: boolean;
  url: string | null;
  error: string | null;
}

interface ServicesContentState {
  fetch: ContentServicesFetchState;
  update: ContentServicesUpdateState;
  uploadImage: ContentServicesUploadImageState;
}

/************COMBINATION FOR ALL SECTIONS*********************** */
export interface SectionState {
  all: AllContentState;
  home: HomeContentState;
  services: ServicesContentState;
}

/* Defaults - ALL */
const defaultContentAllState: AllContentState = {
  loading: false,
  content: null,
  error: null,
};

/* Defaults - HOME */
const defaultContentHomeUploadImageState: ContentHomeUploadImageState = {
  loading: false,
  url: null,
  error: null,
};

const defaultContentHomeFetchState: ContentHomeFetchState = {
  loading: false,
  content: null,
  error: null,
};

const defaultContentHomeUpdateState: ContentHomeUpdateState = {
  loading: false,
  content: null,
  error: null,
};

const defaultHomeContentState: HomeContentState = {
  fetch: defaultContentHomeFetchState,
  update: defaultContentHomeUpdateState,
  uploadImage: defaultContentHomeUploadImageState,
};

/* Defaults - SERVICES */
const defaultContentServicesUploadImageState: ContentServicesUploadImageState = {
  loading: false,
  url: null,
  error: null,
};

const defaultContentServicesFetchState: ContentServicesFetchState = {
  loading: false,
  content: null,
  error: null,
};

const defaultContentServicesUpdateState: ContentServicesUpdateState = {
  loading: false,
  content: null,
  error: null,
};

const defaultServicesContentState: ServicesContentState = {
  fetch: defaultContentServicesFetchState,
  update: defaultContentServicesUpdateState,
  uploadImage: defaultContentServicesUploadImageState,
};

/* For the Entire Section */
const sectionReducerDefaultState: SectionState = {
  all: defaultContentAllState,
  home: defaultHomeContentState,
  services: defaultServicesContentState,
};

/* Actual Reducer function */

const sectionReducer = (
  state: SectionState = sectionReducerDefaultState,
  action: SectionAllAction | SectionHomeAction | SectionServicesAction
): SectionState => {
  switch (action.type) {
    /********************************
     * Fetch Actions
     ********************************/

    case ActionType.SECTION_ALL_FETCH_REQUEST:
      return {
        all: {
          loading: true,
          content: null,
          error: null,
        },
        home: defaultHomeContentState,
        services: defaultServicesContentState,
      };
    case ActionType.SECTION_ALL_FETCH_SUCCESS:
      return {
        all: {
          loading: false,
          content: {
            home: action.payload.sectionHome,
            services: action.payload.sectionServices,
            gallery: action.payload.sectionGallery,
          },
          error: null,
        },
        home: defaultHomeContentState,
        services: defaultServicesContentState,
      };
    case ActionType.SECTION_ALL_FETCH_FAIL:
      return {
        all: {
          loading: false,
          content: null,
          error: action.payload,
        },
        home: defaultHomeContentState,
        services: defaultServicesContentState,
      };

    case ActionType.SECTION_HOME_FETCH_REQUEST:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: {
            loading: true,
            content: null,
            error: null,
          },
          update: defaultContentHomeUpdateState,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_FETCH_SUCCESS:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: {
            loading: false,
            content: action.payload,
            error: null,
          },
          update: defaultContentHomeUpdateState,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_FETCH_FAIL:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: {
            loading: false,
            content: null,
            error: action.payload,
          },
          update: defaultContentHomeUpdateState,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_FETCH_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: {
            ...state.home.fetch,
            error: null,
          },
          update: defaultContentHomeUpdateState,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_FETCH_RESET:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: defaultContentHomeFetchState,
          update: defaultContentHomeUpdateState,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };

    case ActionType.SECTION_SERVICES_FETCH_REQUEST:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: {
            loading: true,
            content: null,
            error: null,
          },
          update: defaultContentServicesUpdateState,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_FETCH_SUCCESS:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: {
            loading: false,
            content: action.payload,
            error: null,
          },
          update: defaultContentServicesUpdateState,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_FETCH_FAIL:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: {
            loading: false,
            content: null,
            error: action.payload,
          },
          update: defaultContentServicesUpdateState,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_FETCH_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: {
            ...state.services.fetch,
            error: null,
          },
          update: defaultContentServicesUpdateState,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_FETCH_RESET:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: defaultContentServicesFetchState,
          update: defaultContentServicesUpdateState,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };

    /********************************
     * Update Actions
     ********************************/
    case ActionType.SECTION_HOME_UPDATE_REQUEST:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          update: {
            loading: true,
            content: null,
            error: null,
          },
          fetch: state.home.fetch,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_UPDATE_SUCCESS:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          update: {
            loading: false,
            content: action.payload,
            error: null,
          },
          fetch: state.home.fetch,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_UPDATE_FAIL:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          update: {
            loading: false,
            content: null,
            error: action.payload,
          },
          fetch: state.home.fetch,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_UPDATE_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          update: {
            ...state.home.update,
            error: null,
          },
          fetch: state.home.fetch,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };
    case ActionType.SECTION_HOME_UPDATE_RESET:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          update: defaultContentHomeUpdateState,
          fetch: state.home.fetch,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };

    case ActionType.SECTION_SERVICES_UPDATE_REQUEST:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          update: {
            loading: true,
            content: null,
            error: null,
          },
          fetch: state.services.fetch,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_UPDATE_SUCCESS:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          update: {
            loading: false,
            content: action.payload,
            error: null,
          },
          fetch: state.services.fetch,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_UPDATE_FAIL:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          update: {
            loading: false,
            content: null,
            error: action.payload,
          },
          fetch: state.services.fetch,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_UPDATE_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          update: {
            ...state.services.update,
            error: null,
          },
          fetch: state.services.fetch,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };
    case ActionType.SECTION_SERVICES_UPDATE_RESET:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          update: defaultContentServicesUpdateState,
          fetch: state.services.fetch,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };

    /********************************
     * Image Upload Actions - Home
     ********************************/
    case ActionType.SECTION_HOME_UPLOAD_IMAGE_REQUEST:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: state.home.fetch,
          update: state.home.update,
          uploadImage: {
            loading: true,
            url: null,
            error: null,
          },
        },
      };
    case ActionType.SECTION_HOME_UPLOAD_IMAGE_SUCCESS:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: state.home.fetch,
          update: state.home.update,
          uploadImage: {
            loading: false,
            url: action.payload,
            error: null,
          },
        },
      };
    case ActionType.SECTION_HOME_UPLOAD_IMAGE_FAIL:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: state.home.fetch,
          update: state.home.update,
          uploadImage: {
            loading: false,
            url: null,
            error: action.payload,
          },
        },
      };
    case ActionType.SECTION_HOME_UPLOAD_IMAGE_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: state.home.fetch,
          update: state.home.update,
          uploadImage: {
            ...state.home.uploadImage,
            error: null,
          },
        },
      };
    case ActionType.SECTION_HOME_UPLOAD_IMAGE_RESET:
      return {
        all: defaultContentAllState,
        services: defaultServicesContentState,
        home: {
          fetch: state.home.fetch,
          update: state.home.update,
          uploadImage: defaultContentHomeUploadImageState,
        },
      };

    /********************************
     * Image Upload Actions - Services
     ********************************/
    case ActionType.SECTION_SERVICES_UPLOAD_IMAGE_REQUEST:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: state.services.fetch,
          update: state.services.update,
          uploadImage: {
            loading: true,
            url: null,
            error: null,
          },
        },
      };
    case ActionType.SECTION_SERVICES_UPLOAD_IMAGE_SUCCESS:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: state.services.fetch,
          update: state.services.update,
          uploadImage: {
            loading: false,
            url: action.payload,
            error: null,
          },
        },
      };
    case ActionType.SECTION_SERVICES_UPLOAD_IMAGE_FAIL:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: state.services.fetch,
          update: state.services.update,
          uploadImage: {
            loading: false,
            url: null,
            error: action.payload,
          },
        },
      };
    case ActionType.SECTION_SERVICES_UPLOAD_IMAGE_CLEAR_ERROR:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: state.services.fetch,
          update: state.services.update,
          uploadImage: {
            ...state.services.uploadImage,
            error: null,
          },
        },
      };
    case ActionType.SECTION_SERVICES_UPLOAD_IMAGE_RESET:
      return {
        all: defaultContentAllState,
        home: defaultHomeContentState,
        services: {
          fetch: state.services.fetch,
          update: state.services.update,
          uploadImage: defaultContentServicesUploadImageState,
        },
      };

    /********************************
     * DEFAULT
     ********************************/
    default: {
      return state;
    }
  }
};

export default sectionReducer;
