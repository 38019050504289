import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';

interface SectionContainerProps {
  index: number;
  sectionCode: string;
  visibleSections: string[];
  setVisibleSections: any;
  visibilitySensorIntervalDelay: number;
  bgColor?: string;
  children: JSX.Element;
}

const SectionContainer: React.FC<SectionContainerProps> = ({
  index,
  sectionCode,
  visibleSections,
  setVisibleSections,
  visibilitySensorIntervalDelay,
  bgColor,
  children,
}: SectionContainerProps) => {
  return (
    <VisibilitySensor
      onChange={(visible: boolean) => {
        if (visible) {
          setVisibleSections([...visibleSections, `${index}-${sectionCode}`]);
        } else {
          setVisibleSections(visibleSections.filter((section: string) => section !== `${index}-${sectionCode}`));
        }
      }}
      intervalCheck={true}
      intervalDelay={visibilitySensorIntervalDelay}
      scrollCheck={false}
      resizeCheck
      partialVisibility={true}
      delayedCall={true}
      offset={{ top: 72 }}
    >
      <section id={`${index}-${sectionCode}`} style={{ backgroundColor: bgColor || '#FFFFFF' }}>
        {children}
      </section>
    </VisibilitySensor>
  );
};

export default SectionContainer;
