import React, { useState, useEffect, useRef, useCallback } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import Header from '../../../components/admin/Header';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@material-ui/core';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useSectionServicesActions } from './../../../hooks/useActions';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import ServicesTabs from './ServicesTabs';
import { useServicesContext, ServicesContextProvider } from './../../../contexts/ServicesContext';
import { darken } from '@material-ui/core/styles/colorManipulator'; /* https://stackoverflow.com/questions/47268652/jss-how-to-change-opacity-for-a-color */
import { ContentServices, Service } from './../../../state/typings';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      paddingTop: '2rem',
    },
    formContainer: {
      paddingTop: '1em',
    },
    mainDescription: {
      fontSize: '1rem',
    },
    buttonContainedPrimary: {
      backgroundColor: theme.palette.common.adminGreenButton,
      '&:hover': {
        backgroundColor: darken(theme.palette.common.adminGreenButton, 0.1),
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.common.white,
    },
    successAlert: {
      backgroundColor: theme.palette.info.dark,
      color: theme.palette.info.contrastText,
    },
    saveCancelButtons: {
      '& button': {
        margin: '5px',
      },
    },
    reactQuillDiv: {
      '& .ql-editor': {
        minHeight: '7rem',
        maxHeight: '7rem',
      },
    },
  };
});

export const modules = {
  toolbar: [
    [{ header: [false] }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ],
};

export const formats = ['header', 'bold', 'italic', 'underline', 'list', 'indent'];

// TODO: check later, need to be reusable
const Alert = (props: AlertProps) => {
  const classes = useStyles();
  return <MuiAlert elevation={4} variant='filled' {...props} classes={{ filledSuccess: classes.successAlert }} />;
};

const ServicesSection: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const quillRef = useRef<ReactQuill | null>(null);
  const [mainDescription, setMainDescription] = useState<string>('');
  const [showError, setShowError] = useState<boolean>(false);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const { fetchSectionServices, updateSectionServices } = useSectionServicesActions();

  const { userInfo } = useTypedSelector((state) => state.userLogin);
  const {
    loading: loadingFetch,
    content: contentFetch,
    error: errorFetch,
  } = useTypedSelector((state) => state.sections.services.fetch);
  const {
    loading: loadingUpdate,
    content: contentUpdate,
    error: errorUpdate,
  } = useTypedSelector((state) => state.sections.services.update);

  // source: https://github.com/zenoamaro/react-quill/issues/391
  const handleQuillRef = useCallback((ref) => {
    if (ref) {
      const quill = ref.getEditor();
      quill.root.setAttribute('spellcheck', 'false'); // instead of boolean, i passed string 'false' to make it work
      ref.focus();
      quillRef.current = ref;
    }
  }, []);

  useEffect(() => {
    if (!userInfo || _.isEmpty(userInfo)) {
      history.replace('/login');
    }
  }, [userInfo, history]);

  useEffect(() => {
    fetchSectionServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingFetch && contentFetch) {
      // alert('content was retrieved');
      setMainDescription(contentFetch.description);
    }
  }, [loadingFetch, contentFetch]);

  useEffect(() => {
    if (!loadingUpdate && contentUpdate) {
      fetchSectionServices();
      setShowSuccess(true);
    }
  }, [loadingUpdate, contentUpdate, fetchSectionServices]);

  useEffect(() => {
    if (!loadingUpdate && errorUpdate) {
      setShowError(true);
    }
  }, [loadingUpdate, errorUpdate]);

  const saveForm = (servicesData: any) => {
    // manually get the html contents of the editor before saving
    // solution found at --> https://lucidar.me/en/rich-content-editor/how-to-get-html-content-from-quill-rich-editor/
    const quill = quillRef.current;
    const editor = quill?.getEditor();
    const _mainDescription = editor?.root.innerHTML || '';

    // Use lodash to remove, '_id' in carouselData
    const contentServices: ContentServices = {
      description: _mainDescription,
      services: servicesData.map((data: Service) => _.omit(data, ['_id'])),
    };

    updateSectionServices(contentServices);
  };

  const SaveCancelButtons = (): JSX.Element => {
    const { servicesData } = useServicesContext();

    return (
      <div className={classes.saveCancelButtons}>
        <Button
          size='large'
          variant='contained'
          color='primary'
          classes={{ containedPrimary: classes.buttonContainedPrimary }}
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => saveForm(servicesData)}
        >
          Save
        </Button>
        <Button
          size='large'
          variant='contained'
          onClick={(evt: React.MouseEvent<HTMLButtonElement>) => history.push('/admin')}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const alertCloseHandler = () => {
    setShowError(false);
    setShowSuccess(false);
  };

  return (
    <>
      <Snackbar
        open={showError || showSuccess}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={5000}
        onClose={() => alertCloseHandler()}
      >
        <>
          {errorUpdate ? (
            <Alert severity='error' onClose={() => alertCloseHandler()}>
              {errorUpdate}
            </Alert>
          ) : (
            <Alert severity='success' onClose={() => alertCloseHandler()}>
              Services Section successfully saved.
            </Alert>
          )}
        </>
      </Snackbar>
      <Header />
      <Container maxWidth='md' className={classes.container}>
        <ServicesContextProvider>
          <form noValidate autoComplete='off'>
            <Grid container direction='column'>
              <Grid container direction='row' justifyContent='center' item>
                <Typography variant='h4'>Services Section</Typography>
              </Grid>
              {!loadingFetch && contentFetch && (
                <Grid container direction='row' item spacing={3} className={classes.formContainer}>
                  <Grid item xs={12}>
                    <Card variant='outlined'>
                      <CardHeader
                        title='Main Description'
                        classes={{
                          title: classes.mainDescription,
                        }}
                      />
                      <CardContent>
                        <ReactQuill
                          ref={handleQuillRef}
                          value={mainDescription}
                          modules={modules}
                          formats={formats}
                          // onChange={handleChange} // dont use this because of performance issue
                          className={classes.reactQuillDiv}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <ServicesTabs initialServicesData={contentFetch.services} />
                  </Grid>
                  <Grid item container direction='row' justifyContent='center' xs={12}>
                    <SaveCancelButtons />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </form>
        </ServicesContextProvider>
      </Container>
      <Backdrop className={classes.backdrop} open={loadingUpdate}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};

export default ServicesSection;
