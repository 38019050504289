/*
We can later add many ActionTypes in here.
*/

export enum ActionType {
  /* USER */
  USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST',
  USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FAIL = 'USER_LOGIN_FAIL',
  USER_LOGIN_CLEAR_ERROR = 'USER_LOGIN_CLEAR_ERROR',
  USER_LOGOUT = 'USER_LOGOUT',

  /* HOME */
  SECTION_HOME_FETCH_REQUEST = 'SECTION_HOME_FETCH_REQUEST',
  SECTION_HOME_FETCH_SUCCESS = 'SECTION_HOME_FETCH_SUCCESS',
  SECTION_HOME_FETCH_FAIL = 'SECTION_HOME_FETCH_FAIL',
  SECTION_HOME_FETCH_CLEAR_ERROR = 'SECTION_HOME_FETCH_CLEAR_ERROR',
  SECTION_HOME_FETCH_RESET = 'SECTION_HOME_FETCH_RESET',

  SECTION_HOME_UPDATE_REQUEST = 'SECTION_HOME_UPDATE_REQUEST',
  SECTION_HOME_UPDATE_SUCCESS = 'SECTION_HOME_UPDATE_SUCCESS',
  SECTION_HOME_UPDATE_FAIL = 'SECTION_HOME_UPDATE_FAIL',
  SECTION_HOME_UPDATE_CLEAR_ERROR = 'SECTION_HOME_UPDATE_CLEAR_ERROR',
  SECTION_HOME_UPDATE_RESET = 'SECTION_HOME_UPDATE_RESET',

  SECTION_HOME_UPLOAD_IMAGE_REQUEST = 'SECTION_HOME_UPLOAD_IMAGE_REQUEST',
  SECTION_HOME_UPLOAD_IMAGE_SUCCESS = 'SECTION_HOME_UPLOAD_IMAGE_SUCCESS',
  SECTION_HOME_UPLOAD_IMAGE_FAIL = 'SECTION_HOME_UPLOAD_IMAGE_FAIL',
  SECTION_HOME_UPLOAD_IMAGE_CLEAR_ERROR = 'SECTION_HOME_UPLOAD_IMAGE_CLEAR_ERROR',
  SECTION_HOME_UPLOAD_IMAGE_RESET = 'SECTION_HOME_UPLOAD_IMAGE_RESET',

  /* SERVICES */
  SECTION_SERVICES_FETCH_REQUEST = 'SECTION_SERVICES_FETCH_REQUEST',
  SECTION_SERVICES_FETCH_SUCCESS = 'SECTION_SERVICES_FETCH_SUCCESS',
  SECTION_SERVICES_FETCH_FAIL = 'SECTION_SERVICES_FETCH_FAIL',
  SECTION_SERVICES_FETCH_CLEAR_ERROR = 'SECTION_SERVICES_FETCH_CLEAR_ERROR',
  SECTION_SERVICES_FETCH_RESET = 'SECTION_SERVICES_FETCH_RESET',

  SECTION_SERVICES_UPDATE_REQUEST = 'SECTION_SERVICES_UPDATE_REQUEST',
  SECTION_SERVICES_UPDATE_SUCCESS = 'SECTION_SERVICES_UPDATE_SUCCESS',
  SECTION_SERVICES_UPDATE_FAIL = 'SECTION_SERVICES_UPDATE_FAIL',
  SECTION_SERVICES_UPDATE_CLEAR_ERROR = 'SECTION_SERVICES_UPDATE_CLEAR_ERROR',
  SECTION_SERVICES_UPDATE_RESET = 'SECTION_SERVICES_UPDATE_RESET',

  SECTION_SERVICES_UPLOAD_IMAGE_REQUEST = 'SECTION_SERVICES_UPLOAD_IMAGE_REQUEST',
  SECTION_SERVICES_UPLOAD_IMAGE_SUCCESS = 'SECTION_SERVICES_UPLOAD_IMAGE_SUCCESS',
  SECTION_SERVICES_UPLOAD_IMAGE_FAIL = 'SECTION_SERVICES_UPLOAD_IMAGE_FAIL',
  SECTION_SERVICES_UPLOAD_IMAGE_CLEAR_ERROR = 'SECTION_SERVICES_UPLOAD_IMAGE_CLEAR_ERROR',
  SECTION_SERVICES_UPLOAD_IMAGE_RESET = 'SECTION_SERVICES_UPLOAD_IMAGE_RESET',

  /* ALL SECTIONS - for main public page */
  SECTION_ALL_FETCH_REQUEST = 'SECTION_ALL_FETCH_REQUEST',
  SECTION_ALL_FETCH_SUCCESS = 'SECTION_ALL_FETCH_SUCCESS',
  SECTION_ALL_FETCH_FAIL = 'SECTION_ALL_FETCH_FAIL',
}
