import axios from 'axios';
import { Dispatch } from 'redux';
import { ActionType } from './../action-types';
import { SectionAllAction } from './../actions';
import { RootState } from './../index';
import { SectionHome, SectionServices, ImageGallery } from './../typings';

export const fetchSectionAll = () => {
  return async (dispatch: Dispatch<SectionAllAction>, getState: () => RootState) => {
    try {
      dispatch({
        type: ActionType.SECTION_ALL_FETCH_REQUEST,
      });

      const { data } = await axios.get(`${process.env.REACT_APP_NODEJS_API_URL}/api/sections`);

      const {
        data: { gallery: dataGallery },
      } = await axios.get(`${process.env.REACT_APP_NODEJS_API_URL}/api/images/s3/gallery`);
      const gallery: [ImageGallery] = dataGallery;

      if (data && data.length > 0) {
        const [sectionHome] = data.filter((section: SectionHome) => section.contentType === 'HomeContent');
        const [sectionServices] = data.filter((section: SectionServices) => section.contentType === 'ServicesContent');

        dispatch({
          type: ActionType.SECTION_ALL_FETCH_SUCCESS,
          payload: {
            sectionHome,
            sectionServices,
            sectionGallery: {
              enabled: true,
              index: 3,
              label: 'Gallery',
              code: 'gallery',
              contentType: 'GalleryContent',
              content: {
                description: 'Change this value later',
                gallery,
              },
            }, // TODO: these values will change to dynamic data when the admin pages for the Section Gallery is done.
          },
        });
      }
    } catch (error: any) {
      let errorMessage = 'unknown error';
      if (error.response && error.response.data.message) {
        errorMessage = error.response.data.message;
      } else if (error.message) {
        errorMessage = error.message;
      }

      dispatch({
        type: ActionType.SECTION_ALL_FETCH_FAIL,
        payload: errorMessage,
      });
    }
  };
};
