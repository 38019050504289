import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { AppBar, Toolbar, Tabs, Tab } from '@material-ui/core';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { MENU_SECTION_ITEMS } from './../pages/MainPage';

interface HeaderProps {
  selectedTabValue: number;
  onHeaderTabMenuClicked: (tabValue: number) => void;
}

interface ElevationScrollProps {
  children: React.ReactElement;
}

export const ElevationScroll = (props: ElevationScrollProps) => {
  const { children } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
};

export const scrollWithOffset = (el: HTMLElement, heightToOffset: number) => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = heightToOffset;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBar: {
      backgroundColor: theme.palette.common.blueHeader,
    },
    toolbarMargin: {
      ...theme.mixins.toolbar,
    },
    tab: {
      minWidth: '10px', // make the narrowest possible, then just add margins on the sides
      marginLeft: '25px',
      fontWeight: 600,
      color: 'rgba(255, 255, 255, 0.55)',
      '&:hover': {
        color: 'rgba(255, 255, 255, 0.75)',
        fontWeight: 700,
      },
    },
    selectedTab: {
      color: 'white',
      backgroundColor: 'rgba(255, 255, 255, 0.20)',
    },
  };
});

const Header = ({ selectedTabValue, onHeaderTabMenuClicked }: HeaderProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
  const heightToOffset = isExtraSmall ? -64 : -70; // also in HomeSection.tsx
  const [tabValue, setTabValue] = useState<number>(0);

  useEffect(() => {
    if (selectedTabValue > -1) {
      setTabValue(selectedTabValue);
    }
  }, [selectedTabValue]);

  return (
    <>
      <ElevationScroll>
        <AppBar position='fixed' className={classes.appBar}>
          <Toolbar disableGutters>
            <Tabs
              value={tabValue}
              onChange={(event: React.ChangeEvent<{}>, value: any) => {
                onHeaderTabMenuClicked(value);
              }}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <Tab
                label={MENU_SECTION_ITEMS[0].label}
                className={classes.tab}
                component={HashLink}
                smooth={false}
                to='#'
                elementId={''}
                scroll={(el: HTMLElement) => scrollWithOffset(el, -90)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[1].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`2-${MENU_SECTION_ITEMS[1].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[2].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`3-${MENU_SECTION_ITEMS[2].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              {/*
              <Tab
                label={MENU_SECTION_ITEMS[3].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`4-${MENU_SECTION_ITEMS[3].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[4].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`5-${MENU_SECTION_ITEMS[4].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[5].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`6-${MENU_SECTION_ITEMS[5].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[6].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`7-${MENU_SECTION_ITEMS[6].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              <Tab
                label={MENU_SECTION_ITEMS[7].label}
                className={classes.tab}
                component={HashLink}
                smooth={true}
                to=''
                elementId={`8-${MENU_SECTION_ITEMS[7].code}`}
                scroll={(el: HTMLElement) => scrollWithOffset(el, heightToOffset)}
                classes={{
                  selected: classes.selectedTab,
                }}
                disableRipple
                disableFocusRipple
              />
              */}
            </Tabs>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <div className={classes.toolbarMargin}></div>
    </>
  );
};

export default Header;
