import React, { useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTypedSelector } from './../../hooks/useTypedSelector';
import { Container, Grid, Typography } from '@material-ui/core';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';
import GalleryPanel from './GalleryPanel';
import GalleryDetails from './GalleryDetails';

const useStyles = makeStyles((theme: Theme) => {
  return {
    rootGalleryPanels: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      width: 100,
      height: 100,
    },
    mainDescriptionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    sectionHeader: {
      fontSize: '2.25rem',
      fontWeight: 800,
      paddingBottom: '2rem',
    },
    galleryWrapper: {
      paddingTop: '2em',
      paddingBottom: '2em',
      // paddingLeft: '12em',
      // paddingRight: '12em',
    },
  };
});

const GallerySection: React.FC = () => {
  const classes = useStyles();
  const [isGalleryPanel, setIsGalleryPanel] = useState<boolean>(true);
  const [selectedGallery, setSelectedGallery] = useState<string | null>(null);
  const { loading, error, content } = useTypedSelector((state) => state.sections.all);

  useEffect(() => {
    if (selectedGallery) {
      setIsGalleryPanel(false);
    } else {
      setIsGalleryPanel(true);
    }
  }, [selectedGallery]);

  if (!loading && content && content.gallery) {
    const { gallery } = content.gallery.content;

    if (gallery && gallery.length > 0) {
      const label = isGalleryPanel ? 'Main Gallery' : `Gallery ${selectedGallery} of ${gallery.length}`;

      return (
        <Container disableGutters={false} maxWidth='lg'>
          <Grid container direction='row' className={classes.galleryWrapper}>
            <Grid item container xs={12} className={classes.mainDescriptionWrapper}>
              <Typography className={classes.sectionHeader}>{label}</Typography>
            </Grid>
            <Grid item xs={12}>
              {isGalleryPanel && <GalleryPanel setSelectedGallery={setSelectedGallery} />}
              {isGalleryPanel || (
                <GalleryDetails setSelectedGallery={setSelectedGallery} code={selectedGallery || ''} />
              )}
            </Grid>
          </Grid>
        </Container>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export default GallerySection;
