import React, { createContext, useContext, useState } from 'react';

export interface ServicesDataItem {
  title: string;
  subTitle: string;
  description: string;
  icon: string;
  imageUrl: string;
}

export interface ServicesDataItems extends Array<ServicesDataItem> {}

export interface ServicesContextValue {
  servicesData: ServicesDataItems | null;
  setServicesData: React.Dispatch<React.SetStateAction<ServicesDataItems | null>>;
}

export const servicesDataDefaultValue: ServicesContextValue = {
  servicesData: [],
  setServicesData: () => {},
};

export const ServicesContext = createContext<ServicesContextValue>(servicesDataDefaultValue);
export const useServicesContext = () => useContext(ServicesContext);

export const ServicesContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [servicesData, setServicesData] = useState<ServicesDataItems | null>(null);

  return (
    <ServicesContext.Provider
      value={{
        servicesData,
        setServicesData,
      }}
    >
      {children}
    </ServicesContext.Provider>
  );
};

/*
resources:
https://www.youtube.com/watch?v=OseG8oQ2RDM
https://wanago.io/2020/09/28/react-context-api-hooks-typescript/
*/
